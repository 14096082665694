import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class SMSCounter extends Component {
  componentDidMount() {
    this.getData();
  }

  constructor(props) {
   super(props);
   this.state = {
     output: [],
     smsList: [],
     smsListByMonthSummary: [],
     schools: [],
     monthToAdd: '1/'+new Date(Date.now()).getFullYear(),
     schoolToAdd: '',
     charges: [],
     charges_original: [],
     chargesBySchool: {},
   }
  }


  getData = async () => {
    axios.get(API + 'root/sms-counter', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(0);
        this.setState({
          output: response.data.data.output,
          smsList: response.data.data.smsList,
          smsListByMonthSummary: response.data.data.smsListByMonthSummary,
          schools: response.data.data.schools,
          charges: response.data.data.charges,
          schoolToAdd: response.data.data.schools[0]._id
        }, () => {
          let charges = this.state.charges;
          let charges_unique = [];
          let charges_list = [];
          charges.forEach((item, i) => {
            let e_ = item.school.name + '_' + item.month;
            if (!charges_unique.includes(e_)) {
              charges_unique.push(e_);
              charges_list.push(item);
            }
          });

          this.setState({charges: charges_list, charges_original: charges_list});

          console.log(this.state.schools);
          let chargesBySchool = {};
          this.state.charges.forEach((item, i) => {
            let checkAvailValue = item.school.name + '_' +item.month.split('/')
            chargesBySchool[item.school._id.toString()] = item.month.split('/')[0];
          });
          this.setState({chargesBySchool: chargesBySchool}, () => {
            console.log(this.state.chargesBySchool);
          })
        });
        console.log(response.data.data);
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  addCharge = async (school, month) => {
    axios.post(API + 'root/sms-counter-charge', {
      school: school,
      month: month
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData();
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  filterMonth = (month) => {
    let charges = this.state.charges_original;
    let add = [];
    charges.forEach((item, i) => {
      if (item.month === month) add.push(item);
    });

    this.setState({charges: add})
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12} style={{marginRight: '1%'}}>
            {/*<h2>הוספת חיוב</h2>
            <p>בית ספר</p>
            <Select onChange={(e) => this.setState({schoolToAdd: e.target.value})} value={this.state.schoolToAdd}>
              {this.state.schools.map((index) => {
                return (
                  <MenuItem value={index._id}>{index.name}</MenuItem>
                )
              })}
            </Select>
            <p>חודש</p>
            <Select onChange={(e) => this.setState({monthToAdd: e.target.value})} value={this.state.monthToAdd}>
              {[1,2,3,4,5,6,7,8,9,10,11,12].map((month) => {
                return (
                  <MenuItem value={month+'/'+new Date(Date.now()).getFullYear()}>{month+'/'+new Date(Date.now()).getFullYear()}</MenuItem>
                )
              })}
            </Select>
            <br /><br />
            <Button onClick={(e) => this.addCharge()} color={'primary'} variant={'contained'}>הוספת חיוב</Button>
            <br /><br /><hr />*/}
            <h1>סנן על פי חודשים</h1>
            <center>
              <div style={{maxWidth: '80%'}}>
                {[1,2,3,4,5,6,7,8,9,10,11,12].map((index) => {
                  return (
                    <Button style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} variant={'outlined'} color={'secondary'} onClick={(e) => this.filterMonth(index+'/'+new Date(Date.now()).getFullYear())}>{index+'/'+new Date(Date.now()).getFullYear()}</Button>
                  );
                })}
              </div>
            </center>

            <h2>מעקב חיובים</h2>
              <center>
                <TableContainer>
                 <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                   <TableHead>
                     <TableRow>
                       <TableCell align="right">בית ספר</TableCell>
                       <TableCell align="right">חודש</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                    {this.state.charges.map((index) => {
                      return (
                        <TableRow>
                          <TableCell align="right">{index.school.name}</TableCell>
                          <TableCell align="right">{index.month}</TableCell>
                        </TableRow>
                      );
                    })}
                   </TableBody>
                 </Table>
               </TableContainer>
              </center>
            <h2>סיכום מסרונים חודשי</h2>
            <center>
              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     <TableCell align="right">תיעוד חיוב</TableCell>
                     <TableCell align="right">מספר מסרונים שנשלחו</TableCell>
                     <TableCell align="right">בית ספר</TableCell>
                     <TableCell align="right">חודש</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                  {Object.keys(this.state.smsListByMonthSummary).reverse().map((index, i) => {
                    let month = Object.keys(this.state.smsListByMonthSummary[index])[0];
                    let number = Object.values(this.state.smsListByMonthSummary[index])[0];
                    return (
                      <TableRow key={i}>
                        <TableCell align="right">
                          <Button variant={'contained'} color={'primary'} onClick={(e) => this.addCharge(index, month)}>סמן כחויב</Button>
                        </TableCell>
                        <TableCell align="right">{number}</TableCell>
                        <TableCell align="right">{index}</TableCell>
                        <TableCell align="right">{month}</TableCell>
                      </TableRow>
                    )
                  })}
                 </TableBody>
               </Table>
             </TableContainer>
            </center>

            <h2>המסרונים האחרונים במערכת</h2>
            <center>
              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     <TableCell align="right">חויב</TableCell>
                     <TableCell align="right">מסרון</TableCell>
                     <TableCell align="right">שם המורה</TableCell>
                     <TableCell align="right">בית ספר</TableCell>
                     <TableCell align="right">תאריך שליחת המסרון</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                  {this.state.smsList.slice(0, 25).map((index, i) => {
                    let charged = false;
                    if (this.state.chargesBySchool[index.teacher.schoolId] === undefined) {
                      charged = false;
                    } else {
                      charged = this.state.chargesBySchool[index.teacher.schoolId].toString() === (new Date(index.createdAt).getMonth() + 1).toString();
                    }
                    return (
                      <TableRow key={i}>
                        <TableCell align="right">{index.msg}</TableCell>
                        <TableCell align="right">{index.teacher.firstName + ' ' + index.teacher.lastName + ' ('+index.teacher.idNumber+')'}</TableCell>
                        <TableCell align="right">{index.school.name}</TableCell>
                        <TableCell align="right">{new Date(index.createdAt).getDate() + '/' + new Date(index.createdAt).getMonth() + 1 + '/' + new Date(index.createdAt).getFullYear()}</TableCell>
                      </TableRow>
                    )
                  })}
                 </TableBody>
               </Table>
             </TableContainer>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SMSCounter;
