import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class ClerksSMS extends Component {
  componentDidMount () {
    this.getData();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: false,
     parentSchoolId: 10,
     frozen: false,
     canAddEmployees: false,
     type: '',
     clerks: [],
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  getData = () => {
    axios.get(API + 'root/clerks-sms', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          clerks: response.data.data.clerks
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changePermission = (id, type) => {
    axios.put(API + 'root/clerks-sms?id='+id+'&type='+type, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>הרשאות פקידים</h2>

              <table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>חייב באישור ע"י מסרון בכניסה למערכת</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>נתק לאחר 5 דק' של אי-שימוש במערכת</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>נתק כל שעה עגולה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מספר טלפון</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>ת.ז</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם הפקיד\ה</th>
                </tr>
                {this.state.clerks.map((index) => {
                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changePermission(index._id, 'requestSMSVerification')}>{index.requestSMSVerification ? 'כן' : 'לא'}</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changePermission(index._id, 'inactivityAutoLogout')}>{index.inactivityAutoLogout ? 'כן' : 'לא'}</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changePermission(index._id, 'everyHourAutoLogout')}>{index.everyHourAutoLogout ? 'כן' : 'לא'}</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.phoneNumber}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.idNumber}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.firstName + ' ' + index.lastName}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default ClerksSMS;
