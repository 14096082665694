import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import Switch from '@material-ui/core/Switch';


import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditInvoices extends Component {
  componentDidMount() {
    this.getSchoolFromQuery();
    this.getSchoolFromQuery2();
  }

  constructor(props) {
   super(props);
   const month = (new Date(Date.now()).getMonth() + 1).toString();
const monthWithLeadingZero = month.padStart(2, '0');
   this.state = {
     schoolId: '',
     school: {},
     schoolName: '',
     isBranch: false,
     parentSchoolId: '',

     schools: [],
     children: 0,

     address: '',
     postalCode: '',
     schoolEmail: '',
     officialIdNumber: '',
     mailAddress: '',
     CPAEmail: '',
     tranzila_supplier: '',
     tranzila_password: '',

     invoiceNumber: 0,
     receiptTaxInvoiceNumber: 0,
     receiptNumber: 0,
     signupNumberOfAllowedPayments: 0,
     insideTestNumberOfAllowedPayments: 0,

     frozen: false,

     wavesCommission: false,
     wavesCommissionSignupTimes: 0,
     wavesCommissionInsideTestTimes: 0,
     wavesCommissionSignupFirstPayment: 0,
     wavesCommissionSignupSecondPayment: 0,
     wavesCommissionInsideTestFirstPayment: 0,
     wavesCommissionInsideTestSecondPayment: 0,
     displayPermissionsNumberTesting: false,
     canManagerPayToEmployees: false,
     haveInsideTests: false,
     haveSignUpFee: false,
     reason: '',
     ptors: [],
     requireInvoiceNumber: false,
     invoiceNumber: false,

     ptorActive: false,
     ptorChosen: {},
     teacherReceiveComission: false,
     signupFeeText: 'דמי רישום',
     smallcashregisterAmount: '0',
     schoolId: '',
     records: [],
     filterMonth: monthWithLeadingZero,
     filterYear: new Date(Date.now()).getFullYear(),
     showFilter: false,
     filtered: true,
     changeFromDate: '',
     changeFromNumber: '',
     changeSpecificRowPrice: false,
     changeSpecificRowPriceId: '',
     changeSpecificRowPricePrice: '',
     changeSpecificRowPriceRecord: {}
   }
  }


    handleHaveInsideTests = () => {
      let haveInsideTests = this.state.haveInsideTests ? false : true;

      this.setState({haveInsideTests: haveInsideTests});
    }

    handleHaveSignUpFee = () => {
      let haveSignUpFee = this.state.haveSignUpFee ? false : true;

      this.setState({haveSignUpFee: haveSignUpFee});
    }

  getSchools = async () => {
    axios.get(API + 'root/schools', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({
          schools: response.data.data.schools,
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }


  editCashRegister = async () => {
    axios.put(API + 'root/school-cashregister?id='+this.state.schoolId, {
        amount: this.state.smallcashregisterAmount
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        alert('הסכום עודכן בהצלחה')
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getSchoolFromQuery = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/school?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
            try {
                this.setState({smallcashregisterAmount: response.data.data.cashregister.amount.toString()})
            } catch (err) {

            }
            try {
                this.setState({schoolId: response.data.data.schoolId})
            } catch (err) {

            }
          this.setState({
            school: response.data.data.schools[0],
            schoolName: response.data.data.schools[0].name,
            isBranch: response.data.data.schools[0].isBranch,
            parentSchoolId: response.data.data.schools[0].parentId,
            address: response.data.data.schools[0].address,
            postalCode: response.data.data.schools[0].postalCode,
            schoolEmail: response.data.data.schools[0].schoolEmail,
            officialIdNumber: response.data.data.schools[0].officialIdNumber,
            displayPermissionsNumberTesting: response.data.data.schools[0].displayPermissionsNumberTesting,
            mailAddress: response.data.data.schools[0].mailAddress,
            invoiceNumber: response.data.data.schools[0].invoiceNumber,
            receiptTaxInvoiceNumber: response.data.data.schools[0].receiptTaxInvoiceNumber,
            receiptNumber: response.data.data.schools[0].receiptNumber,
            CPAEmail: response.data.data.schools[0].CPAEmail,
            isBranch: response.data.data.schools[0].isBranch,
            children: response.data.data.children,
            frozen: response.data.data.schools[0].frozen,
            tranzila_supplier: response.data.data.schools[0].tranzila_supplier,
            tranzila_password: response.data.data.schools[0].tranzila_password,
            signupNumberOfAllowedPayments: response.data.data.schools[0].signupNumberOfAllowedPayments,
            insideTestNumberOfAllowedPayments: response.data.data.schools[0].insideTestNumberOfAllowedPayments,
            haveInsideTests: response.data.data.schools[0].haveInsideTests,
            haveSignUpFee: response.data.data.schools[0].haveSignUpFee,
            canManagerPayToEmployees: response.data.data.schools[0].canManagerPayToEmployees,

            wavesCommission: response.data.data.schools[0].wavesCommission,
            ptors: response.data.data.schools[0].ptors,
            wavesCommissionSignupTimes: response.data.data.schools[0].wavesCommissionSignupTimes,
            wavesCommissionInsideTestTimes: response.data.data.schools[0].wavesCommissionInsideTestTimes,
            wavesCommissionSignupFirstPayment: response.data.data.schools[0].wavesCommissionSignupFirstPayment,
            wavesCommissionSignupSecondPayment: response.data.data.schools[0].wavesCommissionSignupSecondPayment,
            wavesCommissionInsideTestFirstPayment: response.data.data.schools[0].wavesCommissionInsideTestFirstPayment,
            wavesCommissionInsideTestSecondPayment: response.data.data.schools[0].wavesCommissionInsideTestSecondPayment,
            signupFeeText: response.data.data.schools[0].signupFeeText,
          })
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    });
  }

  getSchoolFromQuery2 = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/school-invoices?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
            this.setState({records: response.data.data.invoices});
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    });
  }

  submitChange = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.post(API + 'root/update-invoices-from?id='+this.state.schoolId,{
        'fromDate': this.state.changeFromDate,
        'fromAmount': this.state.changeFromNumber 
      },
       {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
            alert('הרשומות שונו בהצלחה! העמוד מבצע ריפרש על מנת שתוכל לבחון את המספור החדש')
            window.location.reload()
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    });
  }

  changeInvoiceNumbersFromDate = (date) => {
    this.setState({changeFromDate: date})
  }

  submitChangeInvoiceNumbersFromDate = (date) => {
    const a = window.confirm(`אתה בטוח שברצונך לשנות את כל החשבוניות מתאריך ` + this.state.changeFromDate + ` במספר ` + this.state.changeFromNumber + ` ומעלה עד התאריך של היום?`);
    if (a) {
        this.submitChange()
    }
  }

  submitPriceChange = () => {
    const a = window.confirm('האם אתה בטוח שברצונך לשנות את מחיר חשבונית זו?');
    if (a) {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        axios.post(API + 'root/update-specific-invoice-price?id='+this.state.schoolId,{
          'paymentId': this.state.changeSpecificRowPriceId,
          'paymentPrice': this.state.changeSpecificRowPricePrice
        },
        {
          headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
              alert('המחיר שונה בהצלחה');
              // window.location.reload()
              this.getSchoolFromQuery2();
              this.setState({
                changeSpecificRowPrice: false,
                changeSpecificRowPriceId: '',
                changeSpecificRowPricePrice: '',
                changeSpecificRowPriceRecord: {}
              })
          } else {
            alert('שגיאה, אנא נסו שוב');
          }
        })
        .catch(error => {
          alert('שגיאה, אנא נסו שוב');
        });
      });
    }
  }

  changeThisPrice = (record) => {
    this.setState({
      changeSpecificRowPrice: true,
      changeSpecificRowPriceId: record._id.toString(),
      changeSpecificRowPricePrice: record.amountWithVAT,
      changeSpecificRowPriceRecord: record
    })
  }


  reGenerateInvoice = (id) => {
    const a = window.confirm('אנא אשר יצירה מחודשת של חשבונית זו');
    if (a) {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        axios.post(API + 'root/regenerate-invoice?id='+this.state.schoolId,{
          'id': id
        },
        {
          headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
              alert('החשבונית נוצרה מחדש בהצלחה');
              window.open('https://sivan-school.com/pdfs/'+id+'.pdf', '_blank')
              window.open('https://sivan-school.com/pdfs/ob_'+id+'.pdf', '_blank')
          } else {
            alert('החשבונית נוצרה מחדש בהצלחה');
            window.open('https://sivan-school.com/pdfs/'+id+'.pdf', '_blank')
            window.open('https://sivan-school.com/pdfs/ob_'+id+'.pdf', '_blank')
          }
        })
        .catch(error => {
          alert('החשבונית נוצרה מחדש בהצלחה');
          window.open('https://sivan-school.com/pdfs/'+id+'.pdf', '_blank')
          window.open('https://sivan-school.com/pdfs/ob_'+id+'.pdf', '_blank')
        });
      });
    }
  }


  removeInvoice = (id) => {
    const a = window.confirm('האם אתה בטוח שברצונך למחוק רשומת תשלום זאת? זה פעולה שאי אפשר להחזירה!');
    if (a) {
      let name = 'id';
      let url = window.location.href;
      name = name.replace(/[\[\]]/g, '\\$&');
      var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
          results = regex.exec(url);
      if (!results) return null;
      if (!results[2]) return '';
      this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
        axios.post(API + 'root/remove-invoice?id='+this.state.schoolId,{
          'id': id
        },
        {
          headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
        })
        .then(response => {
          console.log(response);
          if (response.data.status === "ok") {
              alert('רשומת התשלום נמחקה בהצלחה')
              // window.location.reload()
              this.getSchoolFromQuery2();
              this.setState({
                changeSpecificRowPrice: false,
                changeSpecificRowPriceId: '',
                changeSpecificRowPricePrice: '',
                changeSpecificRowPriceRecord: {}
              })
          } else {
            alert('שגיאה, אנא נסו שוב');
          }
        })
        .catch(error => {
          alert('שגיאה, אנא נסו שוב');
        });
      });
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
            <h2>חשבוניות / תשלומים- {this.state.schoolName}</h2>
                {/*
                amountWithVAT: 170
                applicationNumber: ""
                applicationType: ""
                bankAccountNumber :  ""
                bankNumber: ""
                branchNumber: ""
                casualClient: false
                casualClientName: ""
                casualClientPhoneNumber: ""
                chequeNum: ""
                createdAt: "2020-11-22T11:31:45.148Z"
                dateOfPayment: "2020-11-22T11:31:45.146Z"
                description: "signupFee"
                invoiceNumber: 12440
                isPtor:  true
                issuerId: "5f55eabdc3c2fe3f4a0499de"
                issuerType : "clerk"
                note: ""
                numberOfItems: ""
                ptorPrecentages: "100"
                ptorReason: "שילם במערכת הישנה"
                refunded : false
                schoolWithrawaled: true
                studentId: "5fba392bb9f381493305bc05"
                transactionId : ""
                type: "ptor"
                updatedAt: "2020-11-22T11:31:45.148Z"
                __v: 0
                _id: "5fba4c21b9f381493305bc1b"
                */}

                
                    <Select placeholder='חודש'
                        value={this.state.filterMonth}
                        onChange={(e) => this.setState({filterMonth: e.target.value})}
                    >
                        <MenuItem value={'01'}>01</MenuItem>
                        <MenuItem value={'02'}>02</MenuItem>
                        <MenuItem value={'03'}>03</MenuItem>
                        <MenuItem value={'04'}>04</MenuItem>
                        <MenuItem value={'05'}>05</MenuItem>
                        <MenuItem value={'06'}>06</MenuItem>
                        <MenuItem value={'07'}>07</MenuItem>
                        <MenuItem value={'08'}>08</MenuItem>
                        <MenuItem value={'09'}>09</MenuItem>
                        <MenuItem value={'10'}>10</MenuItem>
                        <MenuItem value={'11'}>11</MenuItem>
                        <MenuItem value={'12'}>12</MenuItem>
                    </Select>
                    &nbsp;
                    <Select placeholder='שנה'
                        value={this.state.filterYear}
                        onChange={(e) => this.setState({filterYear: e.target.value})}
                    >
                        <MenuItem value={'2019'}>2019</MenuItem>
                        <MenuItem value={'2020'}>2020</MenuItem>
                        <MenuItem value={'2021'}>2021</MenuItem>
                        <MenuItem value={'2022'}>2022</MenuItem>
                        <MenuItem value={'2023'}>2023</MenuItem>
                        <MenuItem value={'2024'}>2024</MenuItem>
                        <MenuItem value={'2025'}>2025</MenuItem>
                        <MenuItem value={'2026'}>2026</MenuItem>
                        <MenuItem value={'2027'}>2027</MenuItem>
                    </Select>
                
                {this.state.changeFromDate !== '' && (
                    <div style={{
                        padding: '5px',
                        border: '1px solid black',
                        borderRadius: 10,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: 'right',
                        direction: 'rtl'
                    }}>
                        <p>אתה מנסה לשנות את החשבוניות מתאריך:<br /> {this.state.changeFromDate}</p>
                        <p>אנא בחר מספר אשר ממנו ואלאה תשנה את מספרי החשבוניות:</p>
                        <input 
                            value={this.state.changeFromNumber}
                            onChange={(e) => this.setState({changeFromNumber: e.target.value})}
                            type={'number'}
                        />
                        <br /><br />

                        <button onClick={(e) => this.submitChangeInvoiceNumbersFromDate()} style={{backgroundColor: 'red', color: 'white', padding: 5, borderColor: 'red'}}>אני בטוח שברצוני לשנות, פעולה זאת אינה ניתנת לשחזור</button>
                    </div>
                )}

                {this.state.changeSpecificRowPrice && (
                    <>
                    <br />
                    <div style={{
                        padding: '5px',
                        border: '1px solid black',
                        borderRadius: 10,
                        marginTop: 5,
                        marginBottom: 5,
                        textAlign: 'right',
                        direction: 'rtl'
                    }}>
                        <p>אתה מנסה לשנות מחיר של חשבונית מספר<br /> {this.state.changeSpecificRowPriceRecord.invoiceNumber}</p>
                        <p>מחיר כולל מע״מ</p>
                        <input 
                            value={this.state.changeSpecificRowPricePrice}
                            onChange={(e) => this.setState({changeSpecificRowPricePrice: e.target.value})}
                            type={'number'}
                        />
                        <br /><br />
                        <button onClick={(e) => this.submitPriceChange()} style={{backgroundColor: 'red', color: 'white', padding: 5, borderColor: 'red'}}>שינוי מחיר</button>
                    </div>
                    </>
                )}

                {this.state.records.map((record) => {
                    if (this.state.filtered) {
                        if (record.dateOfPayment.split('T')[0].includes(this.state.filterYear + '-' + this.state.filterMonth)) {
                            return (
                                <div>
                                    <p>
                                    <button style={{backgroundColor: 'green', borderColor: 'green', color: 'white'}} onClick={() => this.reGenerateInvoice(record._id.toString())}>R</button>
                                    &nbsp;
                                    <button style={{backgroundColor: 'red', borderColor: 'red', color: 'white'}} onClick={() => this.removeInvoice(record._id.toString())}>X</button>
      
                                    &nbsp;
                                        {this.state.changeFromDate === record.dateOfPayment ? (
                                              <button style={{backgroundColor: 'red', color: 'white'}} onClick={() => this.changeInvoiceNumbersFromDate(record.dateOfPayment)}>תיקון מכאן קדימה עד התאריך של היום</button>
                                        ) : (
                                            <button onClick={() => this.changeInvoiceNumbersFromDate(record.dateOfPayment)}>תיקון מכאן קדימה עד התאריך של היום</button>
                                        )}|
                                        הופק בתאריך: {record.dateOfPayment.split('T')[0]} | 
                                        סה״כ עם מע״מ: {record.amountWithVAT} ש״ח | 
                                        מספר חשבונית: {record.invoiceNumber} | 
                                        פתח חשבונית: <a href={record.type === "obligationpayment" ? "https://sivan-school.com/pdfs/ob_" + record._id.toString()+".pdf" : "https://sivan-school.com/pdfs/"+record._id.toString()+".pdf"} target="_blank">פתח</a> |
                                        {this.state.changeSpecificRowPriceId === record._id.toString() ? (
                                              <button style={{backgroundColor: 'red', color: 'white'}} onClick={() => this.changeThisPrice(record)}>שינוי מחיר חשבונית</button>
                                        ) : (
                                            <button onClick={() => this.changeThisPrice(record)}>שינוי מחיר חשבונית</button>
                                        )}|
                                    </p>
                                    <hr />
                                </div>
                            )
                        }
                    }
                })}

            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditInvoices;
