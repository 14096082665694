import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SystemMessages extends Component {
  componentDidMount () {
    this.getMessages();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     messages: [],
     solved: [],
     isClerk: true,
     viewBoard: false,
     deleteBoard: false,
     approveBoard: false,
     updateBoard: false,
     viewCategories: false,
     categoriesColors: false,
     manageGuests: false,
     deleteGuests: false,
     systemMessages: false,
     deleteSystemMessages: false,
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  getMessages = () => {
    axios.get(API + 'root/system-messages', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let messages = response.data.data.messages;
        let solved = [];
        let all = [];

        messages.forEach((message) => {
          if (message.status === 'waiting') all.push(message);

          if (message.status === 'solved') solved.push(message);
        });

        this.setState({
          messages: all,
          solved: solved
        })
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  solved = (id) => {
    axios.put(API + 'root/system-message/solved?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  unsolved = (id) => {
    axios.put(API + 'root/system-message/unsolved?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  remove = (id) => {
    let areYouSure = window.confirm('האם אתה בטוח שברצונך למחוק הודעה זאת?');

    if (areYouSure) {
      axios.delete(API + 'root/system-message?id='+id, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getMessages();
        } else {
          console.log('else');
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right', textDecoration: 'underline'}}>הודעות צרו קשר</h2>

                <div style={{maxWidth: '50%'}}>
                {this.state.messages.reverse().map(index => {
                  return (
                    <Card style={{textAlign: 'right', direction: 'rtl', marginBottom: '2%'}}>
                      <div style={{marginRight: '1%', paddingBottom: '1%', paddingTop: '2%'}}>
                        <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+index.phoneNumber, '_blank')}>
                          <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                          &nbsp;&nbsp;
                          <WhatsAppIcon style={{color: 'white'}}/>
                        </Button>

                        <React.Fragment>
                          <br /><br />
                          <Button variant={'contained'} color={'primary'} onClick={(e) => this.solved(index._id)}>
                            נפתר
                          </Button>
                        </React.Fragment>

                        {((this.state.isClerk && this.state.deleteSystemMessages) || !this.state.isClerk) && (
                          <React.Fragment>
                          <br /><br />
                          <Button variant={'contained'} color={'secondary'} onClick={(e) => this.remove(index._id)}>
                            מחק
                          </Button>
                          </React.Fragment>
                        )}
                        <p><b><u>שם מלא:</u></b> {index.name}</p>
                        <p><b><u>מספר טלפון:</u></b> {index.phoneNumber}</p>
                        <b><u>פנייה:</u></b>
                        <p>{index.text}</p>
                        <b>נשלח בתאריך {new Date(index.createdAt).getDate() + '/' + (new Date(index.createdAt).getMonth() + 1) + '/' + new Date(index.createdAt).getFullYear()}</b>
                      </div>
                    </Card>
                  )
                })}
                </div>
                <h2 style={{textAlign: 'right', textDecoration: 'underline'}}>שנפתרו</h2>

                <div style={{maxWidth: '50%'}}>
                {this.state.solved.reverse().map(index => {
                  return (
                    <Card style={{textAlign: 'right', direction: 'rtl', marginBottom: '2%'}}>
                      <div style={{marginRight: '1%', paddingBottom: '1%', paddingTop: '2%'}}>
                        <Button variant={'contained'} color={'green'} style={{backgroundColor: 'green'}} onClick={(e) => window.open('https://wa.me/+972'+index.phoneNumber, '_blank')}>
                          <span style={{fontSize: '1.2em', color: 'white'}}>יצירת קשר</span>
                          &nbsp;&nbsp;
                          <WhatsAppIcon style={{color: 'white'}}/>
                        </Button>
                        <br /><br />
                        <Button variant={'contained'} color={'primary'} onClick={(e) => this.unsolved(index._id)}>
                          החזר לרשימה
                        </Button>
                        {((this.state.isClerk && this.state.deleteSystemMessages) || !this.state.isClerk) && (
                          <React.Fragment>
                            <br /><br />
                            <Button variant={'contained'} color={'secondary'} onClick={(e) => this.remove(index._id)}>
                              מחק
                            </Button>
                          </React.Fragment>
                        )}
                        <p><b><u>שם מלא:</u></b> {index.name}</p>
                        <p><b><u>מספר טלפון:</u></b> {index.phoneNumber}</p>
                        <b><u>פנייה:</u></b>
                        <p>{index.text}</p>
                        <b>נשלח בתאריך {new Date(index.createdAt).getDate() + '/' + (new Date(index.createdAt).getMonth() + 1) + '/' + new Date(index.createdAt).getFullYear()}</b>
                      </div>
                    </Card>
                  )
                })}
                </div>
              </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SystemMessages;
