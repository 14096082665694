import React, { Component } from 'react';
import '../App.css';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import HomeIcon from '@material-ui/icons/Home';
import Cookies from 'universal-cookie';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import {
  Link
} from "react-router-dom";

const cookies = new Cookies();

class Header extends Component {
  logout = () => {
    alert('התנתקת בהצלחה')
    cookies.remove('token__school');
    window.location.href='/';
  }

  render() {
    return (
      <AppBar position="static" style={{backgroundColor: '#A8A8A8'}}>
        <Toolbar style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>
          <img src={'https://admin.sivan-school.com/images/logo.png'} style={{width: 80}}/>
          {this.props.type === "logged" && (
            <React.Fragment>
              <div style={{display: 'flex', flexDirection: 'row', justifyContent: 'space-between'}}>

                <Button variant="ghost" onClick={(e) => this.logout()}>
                 <ExitToAppIcon style={{color: 'white'}} />
                </Button>

                <Link to='/' style={{textDecoration: 'none'}}><Button variant="ghost">
                 <HomeIcon style={{color: 'white'}} />
                </Button></Link>


              </div>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
    )
  }
}

export default Header;
