import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Dashboard extends Component {
  componentDidMount() {
    this.getData();
    this.getVat();
    this.validate();
    this.getClerks();
    this.getStores();

    this.getAllEmails();
  }

  constructor(props) {
   super(props);
   this.state = {
     schools: [],
     vats: [],
     isClerk: true,
     viewBoard: false,
     deleteBoard: false,
     approveBoard: false,
     updateBoard: false,
     viewCategories: false,
     categoriesColors: false,
     manageGuests: false,
     deleteGuests: false,
     systemMessages: false,
     deleteSystemMessages: false,

     clerks: [],
     stores: [],
     emails: [],
     emailToAdd: false,
     emailToAddText: ''
   }
  }

  getAllEmails = () => {
    axios.get(API + 'root/boardemails', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          emails: response.data.data.emails
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  addEmail = (email) => {
    axios.post(API + 'root/boardemails', {
      email: email
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({emailToAdd: false, emailToAddText: ''})
        this.getAllEmails();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  removeEmail = (email) => {
    axios.delete(API + 'root/boardemails?email='+email, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getAllEmails();
      }
    }).catch(err => {
      console.log(err);
    });
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }

  getData = async () => {
    axios.get(API + 'root/schools-data', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(0);
        this.setState({
          schools: response.data.data.output
        }, () => {
          console.log(this.state.schools);
        })
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getClerks = async () => {
    axios.get(API + 'root/clerks', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(0);
        this.setState({
          clerks: response.data.clerks
        })
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getStores = async () => {
    axios.get(API + 'root/stores', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(0);
        this.setState({
          stores: response.data.data.stores
        })
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getVat = async () => {
    axios.get(API + 'root/vat', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data.data.vats);
        this.setState({
          vats: response.data.data.vats.reverse()
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  changeUsePaymentLink = (schoolId, usePaymentLink) => {
    console.log(usePaymentLink)
    if (usePaymentLink === undefined || usePaymentLink === null) {
      usePaymentLink = true
    } else {
      if (usePaymentLink === true || usePaymentLink === "true") {
        usePaymentLink = false;
      } else {
        usePaymentLink = true;
      }
    }
    axios.put(API + 'root/schools-payment-links?id='+schoolId+'&usePaymentLink='+usePaymentLink,{}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getData()
      }
    });
  };

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12} style={{marginRight: '1%'}}>
            <h4 style={{direction: 'rtl', textAlign: 'right'}}>הגדרות מערכת</h4>
            {!this.state.isClerk && (
              <React.Fragment>
              {this.state.vats.length > 0 && (
                <p style={{direction: 'rtl', textAlign: 'right'}}>מע"מ: {this.state.vats[0].vat}%</p>
              )}
              <Link style={{direction: 'rtl', textAlign: 'right', textDecoration: 'none', float: 'right'}} to='/vat'><Button variant="contained">שינוי הגדרות מע"מ</Button></Link>

              <br /><br /><hr style={{color: '#717171', width: '100%', height: 0.5}} />
              </React.Fragment>
            )}
          </Grid>
          <Grid item xs={12}>
            {!this.state.isClerk && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/sms-counter' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  מעקב ותשלומי מסרונים
                </Button></Link>
              </section>
            )}


            {((this.state.isClerk && this.state.systemMessages) || !this.state.isClerk) && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/system-messages' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  הודעות מיצירת קשר
                </Button></Link>
              </section>
            )}

            {!this.state.isClerk && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/clerks-sms' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  הרשאות פקידים
                </Button></Link>
              </section>
            )}

            {!this.state.isClerk && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/teachers-sms' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  הרשאות מסרונים למורים
                </Button></Link>
              </section>
            )}

            {!this.state.isClerk && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/clerk' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  הוספת פקיד מערכת
                </Button></Link>
              </section>
            )}


            {((this.state.isClerk && this.state.viewBoard) || !this.state.isClerk) && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/board' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  לוח מודעות
                </Button></Link>
              </section>
            )}

            {(((this.state.isClerk && this.state.viewCategories) || (this.state.isClerk && this.state.categoriesColors)) || !this.state.isClerk) && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/categories' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  קטגוריות
                </Button></Link>
              </section>
            )}

            {((this.state.isClerk && this.state.manageGuests) || !this.state.isClerk) && (
            <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
              <Link to='/guests' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                ניהול אורחים
              </Button></Link>
            </section>
            )}

            {(!this.state.isClerk) && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/stores' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  ניהול חנויות
                </Button></Link>
              </section>
            )}

            {(!this.state.isClerk) && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/send-sms-students' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  ניהול הרשאות שליחת מסרונים לתמידים
                </Button></Link>
              </section>
            )}

            <br /><br /><br /><br />
            {/*<section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
              <Link to='/ptors' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                פטורים
              </Button></Link>
            </section>*/}
            <br /><br />

            {!this.state.isClerk && (
              <center>
                <h3 style={{textAlign: 'right', marginRight: '5%'}}>פקידי מערכת</h3>
                <TableContainer>
                 <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                   <TableHead>
                     <TableRow>
                       <TableCell align="right">עריכה</TableCell>
                       <TableCell align="right">שם משתמש</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {this.state.clerks.map((row, i) => (
                       <TableRow key={i}>
                         <TableCell align="right">
                           <Button variant="contained" color={'primary'}>
                            <Link to={'/clerk-edit?id='+row._id} style={{textDecoration: 'none', color: 'white'}}>עריכה</Link>
                           </Button>
                         </TableCell>
                         <TableCell align="right">{row.username}</TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                 </Table>
               </TableContainer>
             </center>
            )}
            <br /><br /><br /><br />

            {!this.state.isClerk && (
              <center>
                {this.state.emailToAdd && (
                  <React.Fragment>
                    <p style={{direction: 'rtl'}}>כתובת מייל:</p>
                    <Button color={'primary'} variant={'contained'} onClick={(e) => this.addEmail(this.state.emailToAddText)}>הוספה</Button>
                    &nbsp;&nbsp;
                    <TextField
                      value={this.state.emailToAddText}
                      onChange={(e) => this.setState({emailToAddText: e.target.value})}
                      style={{
                        width: '30%'
                      }}
                    />
                  </React.Fragment>
                )}

                {!this.state.emailToAdd && (
                  <div style={{textAlign: 'right'}}>
                    <Button color={'primary'} variant={'contained'} style={{textAlign: 'right', marginRight: '5%'}} onClick={(e) => this.setState({emailToAdd: true})}>הוספת מייל חדש</Button>
                  </div>
                )}
                <h3 style={{textAlign: 'right', marginRight: '5%'}}>מיילים לקבלת עדכונים</h3>
                <TableContainer>
                 <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                   <TableHead>
                     <TableRow>
                       <TableCell align="right">מחיקה</TableCell>
                       <TableCell align="right">כתובת מייל</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {this.state.emails.map((row, i) => (
                       <TableRow key={i}>
                         <TableCell align="right">
                           <Button variant="contained" color={'primary'} onClick={(e) => this.removeEmail(row.email)}>
                              מחק
                           </Button>
                         </TableCell>
                         <TableCell align="right">{row.email}</TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                 </Table>
               </TableContainer>
             </center>
            )}
            <br /><br /><br /><br />


            {!this.state.isClerk && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/store/add' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  להוספת חנות חדשה
                </Button></Link>
              </section>
            )}

            {!this.state.isClerk && (
              <center>
                <TableContainer>
                 <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                   <TableHead>
                     <TableRow>
                       <TableCell align="right">עריכה</TableCell>
                       <TableCell align="right">נוצר בתאריך</TableCell>
                       <TableCell align="right">יש מכירות רגילות</TableCell>
                       <TableCell align="right">יש מכירות ברגע אחרון</TableCell>
                       <TableCell align="right">לוגו</TableCell>
                       <TableCell align="right">סלוגן</TableCell>
                       <TableCell align="right">שם המנהל</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {this.state.stores.map((row, i) => (
                        <TableRow key={i}>
                         <TableCell align="right">
                           <Button variant="ghost">
                            <Link to={'/store/edit?id='+row._id} style={{textDecoration: 'none'}}>עריכה</Link>
                           </Button>
                         </TableCell>
                         <TableCell align="right">{new Date(row.createdAt).getDate() + '/' + (new Date(row.createdAt).getMonth() + 1) + '/' + new Date(row.createdAt).getFullYear()}</TableCell>
                         <TableCell align="right">{row.regular ? 'כן' : 'לא'}</TableCell>
                         <TableCell align="right">{row.lastMinute ? 'כן' : 'לא'}</TableCell>
                         <TableCell align="right"><img src={'https://sivan-school.com/images/'+row.logo} style={{width: 50, height: 50}}/></TableCell>
                         <TableCell align="right">{row.slogan}</TableCell>
                         <TableCell align="right">{row.user.firstName + ' ' + row.user.lastName}</TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                 </Table>
               </TableContainer>
             </center>
            )}

            {!this.state.isClerk && (
              <section style={{float: 'right', marginRight: '1%', marginBottom: '2%'}}>
                <Link to='/school/add' style={{textDecoration: 'none'}}><Button variant="contained" color="primary">
                  להוספת בית ספר חדש
                </Button></Link>
              </section>
            )}

            {!this.state.isClerk && (
              <center>
                <TableContainer>
                 <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                   <TableHead>
                     <TableRow>
                       <TableCell align="right">סטטוס בית ספר</TableCell>
                       <TableCell align="right">נתוני בית הספר</TableCell>
                       <TableCell align="right">הוספת סניף</TableCell>
                       <TableCell align="right">עריכת המנהל/ת</TableCell>
                       <TableCell align="right">עריכת בית הספר</TableCell>
                       <TableCell align="right">עריכת סכום קופה קטנה</TableCell>
                       <TableCell align="right">עריכת מספור חשבוניות</TableCell>
                       <TableCell align="right">האם בית ספר משתמש בלינק תשלום בפורטל מורים</TableCell>
                       <TableCell align="right">שם הרשת</TableCell>
                       <TableCell align="right">האם סניף ברשת</TableCell>
                       <TableCell align="right">שם המנהל/ת</TableCell>
                       <TableCell align="right">שם הבית ספר</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {this.state.schools.map((row, i) => (
                       <TableRow key={i}>
                         <TableCell align="right">
                          {row.frozen ? (
                            <p style={{color: 'red', fontWeight: 'bold'}}>מושהה</p>
                          ) : (
                            <p style={{color: 'green', fontWeight: 'bold'}}>פעיל</p>
                          )}
                         </TableCell>
                         <TableCell align="right">
                           <Button variant="ghost">
                             <VisibilityIcon />
                           </Button>
                         </TableCell>
                         <TableCell align="right">
                           <Button variant="ghost">
                            {row.isBranch !== 'לא' ? (
                              <AddCircleOutlineIcon style={{color: '#717171'}} onClick={(e) => alert('אין אפשרות ליצור סניף חדש מסניף, אנא צור סניף חדש מהבית ספר הראשי')}/>
                            ) : (
                              <Link to={'/school/branch/add?id='+row._id+'&school='+row.name} style={{textDecoration: 'none'}}><AddCircleOutlineIcon style={{color: 'black'}}/></Link>
                            )}
                           </Button>
                         </TableCell>
                         <TableCell align="right">
                           <Button variant="ghost">
                            <Link to={'/manager/update?id='+row._id} style={{textDecoration: 'none'}}><FaceIcon style={{color: 'black'}}/></Link>
                           </Button>
                         </TableCell>
                         <TableCell align="right">
                           <Button variant="ghost">
                            <Link to={'/school/update?id='+row._id} style={{textDecoration: 'none'}}><EditIcon style={{color: 'black'}}/></Link>
                           </Button>
                         </TableCell>
                         <TableCell align="right">
                           <Button variant="ghost">
                            <Link to={'/school/update-smallcash?id='+row._id} style={{textDecoration: 'none'}}><EditIcon style={{color: 'black'}}/></Link>
                           </Button>
                         </TableCell>
                         <TableCell align="right">
                           <Button variant="ghost">
                            <Link to={'/school/update-invoices?id='+row._id} style={{textDecoration: 'none'}}><EditIcon style={{color: 'black'}}/></Link>
                           </Button>
                         </TableCell>
                         <TableCell align="right">
                          <button onClick={() => this.changeUsePaymentLink(row._id, row.usePaymentLink)}>{row.usePaymentLink ? 'כן' : 'לא'}</button>
                         </TableCell>
                         <TableCell align="right">{row.branchName}</TableCell>
                         <TableCell align="right">{row.isBranch}</TableCell>
                         <TableCell align="right">{row.managerName}</TableCell>
                         <TableCell align="right">{row.name}</TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                 </Table>
               </TableContainer>
             </center>
            )}

          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Dashboard;
