import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';

import Cookies from 'universal-cookie';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Login extends Component {

  constructor(props) {
   super(props);
   this.state = {
     username: '',
     password: ''
   }
  }

  handleTextField = (value, type) => {
    if (type === 'username') this.setState({'username': value});
    else this.setState({'password': value});
  }

  login = async () => {
    if (this.state.username !== '' && this.state.password !== '') {
      axios.post(API + 'root/login', {
        username: this.state.username,
        password: this.state.password
      })
      .then(function (response) {
        if (response.data.status === "ok") {
          cookies.set('token__school', response.data.data.token, { path: '/' });
          window.location.reload();
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(function (error) {
        alert('שגיאה, אנא נסו שוב');
      });
    } else {
      alert('שגיאה, אחד הנתונים לא מלאים.');
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'login'} />
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2><b>התחברות</b></h2>

              <p style={{textAlign: 'right'}}>שם משתמש</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                onChange={(e) => this.handleTextField(e.target.value, 'username')}
              />

              <p style={{textAlign: 'right'}}>סיסמה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type="password"
                onChange={(e) => this.handleTextField(e.target.value, 'password')}
              />


              <Button onClick={(e) => this.login()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1em', width: '20%'}}>
                התחברות
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Login;
