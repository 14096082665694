import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Categories extends Component {
  componentDidMount () {
    this.getCategories();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: false,
     parentSchoolId: 10,
     frozen: false,
     canAddEmployees: false,
     type: '',
     categories: [],
     board: [],
     addCategory: false,
     name: '',
     editCategory: false,
     chosenId: '',
     parentCategory: '',
     color: '#ffffff',
     fontColor: '#ffffff',

     isClerk: true,
     viewBoard: false,
     deleteBoard: false,
     approveBoard: false,
     updateBoard: false,
     viewCategories: false,
     categoriesColors: false,
     manageGuests: false,
     deleteGuests: false,
     systemMessages: false,
     deleteSystemMessages: false,

     mustExpdate: false,
     mustImage: true,
     showCostPrice: true,
     showPriceWanted: true,
     showDiscount: true,
     isBuy: false,
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  getCategories = () => {
    axios.get(API + 'root/sivanboardcategories', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          categories: response.data.data.categories
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  deleteCategory = (id) => {
    axios.delete(API + 'root/sivanboardcategory?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getCategories();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  addCategory = () => {
    axios.post(API + 'root/sivanboardcategory', {
      name: this.state.name,
      nameEnglish: this.state.nameEnglish,
      nameRussian: this.state.nameRussian,
      nameArabic: this.state.nameArabic,
      color: this.state.color,
      fontColor: this.state.fontColor,
      parentCategory: this.state.parentCategory,
      mustExpdate: this.state.mustExpdate,
      mustImage: this.state.mustImage,
      showCostPrice: this.state.showCostPrice,
      showPriceWanted: this.state.showPriceWanted,
      showDiscount: this.state.showDiscount,
      isBuy: this.state.isBuy,
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({addCategory: false, name: ''})
        this.getCategories();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  igniteEdition = (index) => {
    this.setState({
      editCategory: true,
      color: index.color,
      fontColor: index.fontColor,
      name: index.name,
      nameEnglish: index.nameEnglish,
      nameRussian: index.nameRussian,
      nameArabic: index.nameArabic,
      chosenId: index._id,
      mustExpdate: index.mustExpdate,
      mustImage: index.mustImage,
      showCostPrice: index.showCostPrice,
      showPriceWanted: index.showPriceWanted,
      showDiscount: index.showDiscount,
      isBuy: index.isBuy
    }, () => {
      window.scrollTo(0, 0)
    });
  }

  reset = () => {
    this.setState({
      mustExpdate: false,
      mustImage: false,
      showCostPrice: false,
      showPriceWanted: false,
      showDiscount: false,
      isBuy: false,
    })
  }
  updateCategory = (id, name) => {
    axios.put(API + 'root/sivanboardcategory?id='+id, {
      name: this.state.name,
      nameEnglish: this.state.nameEnglish,
      nameRussian: this.state.nameRussian,
      nameArabic: this.state.nameArabic,
      color: this.state.color,
      fontColor: this.state.fontColor,
      parentCategory: this.state.parentCategory,
      mustExpdate: this.state.mustExpdate,
      mustImage: this.state.mustImage,
      showCostPrice: this.state.showCostPrice,
      showPriceWanted: this.state.showPriceWanted,
      showDiscount: this.state.showDiscount,
      isBuy: this.state.isBuy,
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({addCategory: false, name: '', nameEnglish: '', nameArabic: '', nameRussian: '', editCategory: false})
        this.getCategories();
        this.reset();
      } else {
        console.log('else');
        this.reset();
      }
    })
    .catch(error => {
      console.log(error);
      this.reset();
    });
  }

  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleChangeCompleteFont = (color) => {
    this.setState({ fontColor: color.hex });
  };

  mustExpdateChange = () => {
    let value = this.state.mustExpdate;

    this.setState({mustExpdate: value ? false : true});
  }

  mustImageChange = () => {
    let value = this.state.mustImage;

    this.setState({mustImage: value ? false : true});
  }

  showCostPriceChange = () => {
    let value = this.state.showCostPrice;

    this.setState({showCostPrice: value ? false : true});
  }

  showPriceWantedChange = () => {
    let value = this.state.showPriceWanted;

    this.setState({showPriceWanted: value ? false : true});
  }

  showDiscountChange = () => {
    let value = this.state.showDiscount;

    this.setState({showDiscount: value ? false : true});
  }

  isBuyChange = () => {
    let value = this.state.isBuy;

    this.setState({isBuy: value ? false : true});
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>קטגוריות (מכירה/קנייה)</h2>

              {this.state.addCategory ? (
                <React.Fragment>
                <Button color={'secondary'} variant={'contained'} style={{
                  textAlign: 'right',
                }} onClick={(e) => this.setState({addCategory: false, name: ''})}>ביטול</Button>
                <br /><br />
                {((this.state.isClerk && this.state.viewCategories) || !this.state.isClerk) && (
                <React.Fragment>
                <TextField
                  value={this.state.name}
                  onChange={(e) => this.setState({name: e.target.value})}
                  style={{
                    width: '90%',
                    textAlign: 'right',
                    direction: 'rtl'
                  }}
                  placeholder={'שם הקטגוריה'} />
                <TextField
                  value={this.state.nameEnglish}
                  onChange={(e) => this.setState({nameEnglish: e.target.value})}
                  style={{
                    width: '90%',
                    textAlign: 'right',
                    direction: 'rtl'
                  }}
                  placeholder={'שם הקטגוריה באנגלית'} />
                <TextField
                  value={this.state.nameArabic}
                  onChange={(e) => this.setState({nameArabic: e.target.value})}
                  style={{
                    width: '90%',
                    textAlign: 'right',
                    direction: 'rtl'
                  }}
                  placeholder={'שם הקטגוריה בערבית'} />
                <TextField
                  value={this.state.nameRussian}
                  onChange={(e) => this.setState({nameRussian: e.target.value})}
                  style={{
                    width: '90%',
                    textAlign: 'right',
                    direction: 'rtl'
                  }}
                  placeholder={'שם הקטגוריה ברוסית'} />

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>בחר קטוגריית "אב" (לא חובה)</p>
                <Select
                  onChange={(e) => this.setState({parentCategory: e.target.value})}
                  value={this.state.parentCategory}
                  style={{
                    width: '90%',
                    textAlign: 'right',
                    direction: 'rtl'
                  }}
                >
                <MenuItem value={''} style={{
                  textAlign: 'right',
                  direction: 'rtl'
                }}>{'ללא'}</MenuItem>
                  {this.state.categories.map((index) => {
                    return (
                      <MenuItem value={index.name} style={{
                        textAlign: 'right',
                        direction: 'rtl'
                      }}>{index.name}</MenuItem>
                    );
                  })}
                </Select>
                <br />
                <Checkbox
                  checked={this.state.mustExpdate}
                  onChange={(e) => this.mustExpdateChange()}
                /> חובה לבחור תאריך תפוגה למוצר
                <br />
                <Checkbox
                  checked={this.state.mustImage}
                  onChange={(e) => this.mustImageChange()}
                /> חובה להעלות תמונה
                <br />
                <Checkbox
                  checked={this.state.showCostPrice}
                  onChange={(e) => this.showCostPriceChange()}
                /> הצג מחיר עלות
                <br />
                <Checkbox
                  checked={this.state.showPriceWanted}
                  onChange={(e) => this.showPriceWantedChange()}
                /> הצג מחיר רצוי
                <br />
                <Checkbox
                  checked={this.state.showDiscount}
                  onChange={(e) => this.showDiscountChange()}
                /> הצג הנחה
                <br />
                <Checkbox
                  checked={this.state.isBuy}
                  onChange={(e) => this.isBuyChange()}
                /> האם קנייה (אם לא - אזי קטגוריית מכירה)
                </React.Fragment>
                )}

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>צבע רקע</p>
                <ChromePicker
                  color={this.state.color}
                  onChangeComplete={this.handleChangeComplete}
                />
                <br />
                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>צבע פונט</p>
                <ChromePicker
                  color={this.state.fontColor}
                  onChangeComplete={this.handleChangeCompleteFont}
                />

                  <br /><br />
                  <Button color={'primary'} variant={'contained'} style={{
                    textAlign: 'right',
                  }} onClick={(e) => this.addCategory()}>הוספה</Button>
                </React.Fragment>
              ) : (
                <React.Fragment>
                {((this.state.isClerk && this.state.viewCategories) || !this.state.isClerk) && (
                  <Button color={'secondary'} variant={'contained'} style={{
                    textAlign: 'right',
                  }} onClick={(e) => this.setState({addCategory: true})}>הוספת קטגוריה חדשה +</Button>
                )}
                </React.Fragment>
              )}

              {this.state.editCategory && (
                <React.Fragment>
                  {((this.state.isClerk && this.state.viewCategories) || !this.state.isClerk) && (
                    <React.Fragment>
                  <TextField
                    value={this.state.name}
                    onChange={(e) => this.setState({name: e.target.value})}
                    style={{
                      width: '90%',
                      textAlign: 'right',
                      direction: 'rtl'
                    }}
                    placeholder={'שם הקטגוריה'} />
                    <TextField
                      value={this.state.nameEnglish}
                      onChange={(e) => this.setState({nameEnglish: e.target.value})}
                      style={{
                        width: '90%',
                        textAlign: 'right',
                        direction: 'rtl'
                      }}
                      placeholder={'שם הקטגוריה באנגלית'} />
                    <TextField
                      value={this.state.nameArabic}
                      onChange={(e) => this.setState({nameArabic: e.target.value})}
                      style={{
                        width: '90%',
                        textAlign: 'right',
                        direction: 'rtl'
                      }}
                      placeholder={'שם הקטגוריה בערבית'} />
                      <TextField
                        value={this.state.nameRussian}
                        onChange={(e) => this.setState({nameRussian: e.target.value})}
                        style={{
                          width: '90%',
                          textAlign: 'right',
                          direction: 'rtl'
                        }}
                        placeholder={'שם הקטגוריה ברוסית'} />
                    <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>בחר קטוגריית "אב" (לא חובה)</p>
                    <Select
                      onChange={(e) => this.setState({parentCategory: e.target.value})}
                      value={this.state.parentCategory}
                      style={{
                        width: '90%',
                        textAlign: 'right',
                        direction: 'rtl'
                      }}
                    >
                    <MenuItem value={''} style={{
                      textAlign: 'right',
                      direction: 'rtl'
                    }}>{'ללא'}</MenuItem>
                      {this.state.categories.map((index) => {
                        return (
                          <MenuItem value={index.name} style={{
                            textAlign: 'right',
                            direction: 'rtl'
                          }}>{index.name}</MenuItem>
                        );
                      })}
                    </Select>
                    <br />
                    <Checkbox
                      checked={this.state.mustExpdate}
                      onChange={(e) => this.mustExpdateChange()}
                    /> חובה לבחור תאריך תפוגה למוצר
                    <br />
                    <Checkbox
                      checked={this.state.mustImage}
                      onChange={(e) => this.mustImageChange()}
                    /> חובה להעלות תמונה
                    <br />
                    <Checkbox
                      checked={this.state.showCostPrice}
                      onChange={(e) => this.showCostPriceChange()}
                    /> הצג מחיר עלות
                    <br />
                    <Checkbox
                      checked={this.state.showPriceWanted}
                      onChange={(e) => this.showPriceWantedChange()}
                    /> הצג מחיר רצוי
                    <br />
                    <Checkbox
                      checked={this.state.showDiscount}
                      onChange={(e) => this.showDiscountChange()}
                    /> הצג הנחה
                    <br />
                    <Checkbox
                      checked={this.state.isBuy}
                      onChange={(e) => this.isBuyChange()}
                    /> האם קנייה (אם לא - אזי קטגוריית מכירה)
                  </React.Fragment>
                  )}
                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>צבע רקע</p>
                  <ChromePicker
                    color={this.state.color}
                    onChangeComplete={this.handleChangeComplete}
                  />
                  <br />
                  <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>צבע פונט</p>
                  <ChromePicker
                    color={this.state.fontColor}
                    onChangeComplete={this.handleChangeCompleteFont}
                  />

                  <br /><br />
                  <Button color={'primary'} variant={'contained'} style={{
                    textAlign: 'right',
                  }} onClick={(e) => this.updateCategory(this.state.chosenId, this.state.name)}>אישור</Button>
                </React.Fragment>
              )}

              <br /><br />
              <table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>עריכה</th>
                  {((this.state.isClerk && this.state.viewCategories) || !this.state.isClerk) && (<th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיקה</th>)}
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>קנייה\מכירה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם מציג הנחה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם מציג מחיר מבוקש</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם מציג מחיר קנייה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם חובה להעלות תמונה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם חובה לבחור תאריך תפוגה למוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>צבע פונט</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>צבע רקע</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>קטגוריית אב</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם ברוסית</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם בערבית</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם באנגלית</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם</th>
                </tr>
                {this.state.categories.map((index) => {
                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.igniteEdition(index)}>ערוך</Button></td>
                      {((this.state.isClerk && this.state.viewCategories) || !this.state.isClerk) && (<td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.deleteCategory(index._id)}>מחק</Button></td>)}
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.isBuy ? 'קנייה' : 'מכירה'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.showDiscount ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.showPriceWanted ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.showCostPrice ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.mustImage ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.mustExpdate ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8, color: index.fontColor === '#ffffff' ? 'black' : index.fontColor}}>{index.fontColor}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8, color: index.color}}>{index.color}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.parentCategory}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.nameRussian}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.nameArabic}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.nameEnglish}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.name}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Categories;
