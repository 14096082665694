import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class AddBranch extends Component {
  componentDidMount() {
    this.getSchoolFromQuery();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: true,
     name: '',
     firstName: '',
     lastName: '',
     idNumber: '',
     email: '',
     phoneNumber: '',
     password: '',
     schoolParentId: '',
     parentName: '',

     address: '',
     postalCode: '',
     schoolEmail: '',
     officialIdNumber: '',
     CPAEmail: '',
     mailAddress: '',

     invoiceNumber: 0,
     receiptTaxInvoiceNumber: 0,
     receiptNumber: 0,
     signupNumberOfAllowedPayments: '',
     insideTestNumberOfAllowedPayments: '',
     type: '',
   }
  }

  handleChange = () => {
    let isBranch = this.state.isBranch ? false : true;

    this.setState({isBranch: isBranch});
  }

  getParams = (name) => {
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';

    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  getSchoolFromQuery = () => {
    this.setState({schoolParentId: this.getParams('id'), parentName: this.getParams('school')});
  }


  createSchool = async () => {
    if (this.state.name !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '') {
      let payload = {
        name: this.state.name,

        address: this.state.address,
        postalCode: this.state.postalCode,
        schoolEmail: this.state.schoolEmail,
        officialIdNumber: this.state.officialIdNumber,
        mailAddress: this.state.mailAddress,
        CPAEmail: this.state.CPAEmail,

        canAddEmployees: this.state.canAddEmployees,
        tranzila_supplier: this.state.tranzila_supplier,
        type: this.state.type,

        invoiceNumber: this.state.invoiceNumber,
        receiptTaxInvoiceNumber: this.state.receiptTaxInvoiceNumber,
        receiptNumber: this.state.receiptNumber,

        signupNumberOfAllowedPayments: this.state.signupNumberOfAllowedPayments,
        insideTestNumberOfAllowedPayments: this.state.insideTestNumberOfAllowedPayments,

        schoolParentId: this.state.schoolParentId,

        firstName: this.state.firstName,
        lastName: this.state.lastName,
        idNumber: this.state.idNumber,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password
      };

      axios.post(API + 'root/manager-school', payload, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert('בית ספר חדש נוצר בהצלחה');
          window.location.href='/';
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    } else {
      alert('חובה למלא את כל שדות החובה');
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>הוספת סניף בית ספר חדש לבית הספר {this.state.parentName}</h2>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>כתובת בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.address}
                onChange={(e) => this.setState({address: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>מיקוד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.postalCode}
                onChange={(e) => this.setState({postalCode: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>אי-מייל בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.schoolEmail}
                onChange={(e) => this.setState({schoolEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>אי-מייל רואה חשבון</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.CPAEmail}
                onChange={(e) => this.setState({CPAEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>ח.פ/ע.מ</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.officialIdNumber}
                onChange={(e) => this.setState({officialIdNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>כתובת למשלוח דואר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.mailAddress}
                onChange={(e) => this.setState({mailAddress: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית מס קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.receiptTaxInvoiceNumber}
                onChange={(e) => this.setState({receiptTaxInvoiceNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.invoiceNumber}
                onChange={(e) => this.setState({invoiceNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית מס קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.signupNumberOfAllowedPayments}
                onChange={(e) => this.setState({insideTestNumberOfAllowedPayments: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.signupNumberOfAllowedPayments}
                onChange={(e) => this.setState({insideTestNumberOfAllowedPayments: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.receiptNumber}
                onChange={(e) => this.setState({receiptNumber: e.target.value})}
              />


              <h3 style={{textAlign: 'right'}}>הוספת מנהל/ת</h3>

              <p style={{textAlign: 'right'}}>משתמש מעורב</p>
              <Select
                value={this.state.type}
                onChange={(e) => this.setState({type: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'teacher'}>מורה</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'professional'}>מנהל מקצועי</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'professionalteacher'}>מורה / מנהל מקצועי</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'clerk'}>פקיד/ה</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם פרטי</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם משפחה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>ת.ז</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.idNumber}
                onChange={(e) => this.setState({idNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> אימייל</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>מספר טלפון</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

              <p style={{textAlign: 'right', fontWeight: 'bold', direction: 'rtl'}}>האם יכול להוסיף משתמשים?</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.canAddEmployees}
                onChange={(e) => this.setState({canAddEmployees: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                  <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>כן</MenuItem>
                  <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>לא</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}>שם המסוף</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.tranzila_supplier}
                onChange={(e) => this.setState({tranzila_supplier: e.target.value})}
              />


              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סיסמה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='password'
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />

              <Button onClick={(e) => this.createSchool()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                להוספה
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddBranch;
