import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import DeleteIcon from '@material-ui/icons/Delete';
import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Guests extends Component {
  componentDidMount() {
    this.getData();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     guests: [],
     isClerk: true,
     viewBoard: false,
     deleteBoard: false,
     approveBoard: false,
     updateBoard: false,
     viewCategories: false,
     categoriesColors: false,
     manageGuests: false,
     deleteGuests: false,
     systemMessages: false,
     deleteSystemMessages: false,
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }

  getData = async () => {
    axios.get(API + 'root/guests', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(0);
        this.setState({
          guests: response.data.data.guests
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  pad = (n) => {
    return n<10 ? '0'+n : n
  }

  modifyDate = (date) => {
    let d = new Date(date);

    return this.pad(d.getDay()) + '/' + this.pad(d.getMonth() + 1) + '/' + d.getFullYear();

  }

  deleteUser = (id) => {
    let confirmDeletion = window.confirm("האם אתה בטוח שברצונך למחוק אורח זה! לא תהיה אפשרות לשחזר את הלקוח לאחר המחיקה!");
    if (confirmDeletion == true) {
      axios.delete(API + 'root/guest?id='+id, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        this.getData();
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12} style={{marginRight: '1%'}}>
            <h4 style={{direction: 'rtl', textAlign: 'right'}}>ניהול אורחים</h4>
          </Grid>
          <Grid item xs={12}>
            <center>
              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     {((this.state.isClerk && this.state.deleteGuests) || !this.state.isClerk) && (<TableCell align="right">מחיקת משתמש</TableCell>)}
                     <TableCell align="right">אושר בתאריך</TableCell>
                     <TableCell align="right">מספר פרסומים</TableCell>
                     <TableCell align="right">מספר טלפון</TableCell>
                     <TableCell align="right">אימייל</TableCell>
                     <TableCell align="right">שם מלא</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {this.state.guests.map((row, i) => (
                     <TableRow key={i}>
                       {((this.state.isClerk && this.state.deleteGuests) || !this.state.isClerk) && (
                         <TableCell align="right">
                           <Button variant="ghost" onClick={(e) => this.deleteUser(row._id)}>
                             <DeleteIcon />
                           </Button>
                         </TableCell>
                       )}
                       <TableCell align="right">{this.modifyDate(row.createdAt)}</TableCell>
                       <TableCell align="right">{row.numberOfMessages}</TableCell>
                       <TableCell align="right">{row.phoneNumber}</TableCell>
                       <TableCell align="right">{row.email}</TableCell>
                       <TableCell align="right">{row.firstName + ' ' + row.lastName}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
           </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Guests;
