import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditManager extends Component {
  componentDidMount () {
    this.getManagerFromQuery();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: false,
     parentSchoolId: 10,
     frozen: false,
     canAddEmployees: false,
     type: ''
   }
  }

  handleChange = () => {
    let isBranch = this.state.isBranch ? false : true;

    this.setState({isBranch: isBranch});
  }

  getManagerFromQuery = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/manager-by-school?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          if (response.data.manager === null) {
            this.setState({
              firstName: '',
              lastName: '',
              idNumber: '',
              phoneNumber: '',
              email: '',
              type: '',
              frozen: false
            })
          } else {
            this.setState({
              firstName: response.data.data.manager.firstName,
              lastName: response.data.data.manager.lastName,
              idNumber: response.data.data.manager.idNumber,
              phoneNumber: response.data.data.manager.phoneNumber,
              email: response.data.data.manager.email,
              frozen: response.data.data.manager.frozen,
              canAddEmployees: response.data.data.manager.canAddEmployees,
              type: response.data.data.manager.type === 'manager' ? '' : response.data.data.manager.type
            })
          }
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        // alert('שגיאה, אנא נסו שוב2');
      });
    });
  }

  updateManager = () => {
    let payload = {
      firstName: this.state.firstName,
      lastName: this.state.lastName,
      idNumber: this.state.idNumber,
      email: this.state.email,
      phoneNumber: this.state.phoneNumber,
      password: this.state.password,
      frozen: this.state.frozen,
      canAddEmployees: this.state.canAddEmployees,
      type: this.state.type
    }

    axios.put(API + 'root/manager-by-school?id='+this.state.schoolId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        alert('המנהל/ת עודכנ/ה בהצלחה');
        window.location.href='/';
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  freeze = () => {
    let frozen = this.state.frozen ? false : true;

    this.setState({frozen: frozen});
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>עריכת המנהל/ת</h2>

              <div style={{float: 'right', fontWeight: 'bold'}}>
                <Switch
                   checked={this.state.frozen}
                   onChange={(e) => this.freeze()}
                /> ?הקפאת משתמש
              </div>
              <br /><br />

              <p style={{textAlign: 'right'}}>משתמש מעורב</p>
              <Select
                value={this.state.type}
                onChange={(e) => this.setState({type: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'teacher'}>מורה</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'professional'}>מנהל מקצועי</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'professionalteacher'}>מורה / מנהל מקצועי</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'clerk'}>פקיד/ה</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם פרטי</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם משפחה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>ת.ז</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.idNumber}
                onChange={(e) => this.setState({idNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> אימייל</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>מספר טלפון</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>סיסמה (אם ישאר ריק, הסיסמה לא תשונה)</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='password'
                onChange={(e) => this.setState({password: e.target.value})}
              />

              <p style={{textAlign: 'right', fontWeight: 'bold', direction: 'rtl'}}>האם יכול להוסיף משתמשים?</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.canAddEmployees}
                onChange={(e) => this.setState({canAddEmployees: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                  <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>כן</MenuItem>
                  <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>לא</MenuItem>
              </Select>

              <Button onClick={(e) => this.updateManager()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                לחץ לעריכה
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditManager;
