import React, { Component } from 'react';
import './App.css';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from "react-router-dom";

import Dashboard from './pages/Dashboard';
import AddSchool from './pages/AddSchool';
import EditSchool from './pages/EditSchool';
import EditSchoolSmallCash from './pages/EditSchoolSmallCash';
import EditManager from './pages/EditManager';
import AddBranch from './pages/AddBranch';
import EditInvoices from './pages/EditInvoices';
import SchoolWatch from './pages/SchoolWatch';
import Ptors from './pages/Ptors';
import Vat from './pages/Vat';
import Login from './pages/Login';
import Board from './pages/Board';
import EditBoard from './pages/EditBoard';
import Categories from './pages/Categories';
import Guests from './pages/Guests';
import SystemMessages from './pages/SystemMessages';
import AddClerk from './pages/AddClerk';
import EditClerk from './pages/EditClerk';
import Stores from './pages/Stores';
import AddStore from './pages/AddStore';
import EditStore from './pages/EditStore';
import TeachersSMS from './pages/TeachersSMS';
import ClerksSMS from './pages/ClerksSMS';
import SMSCounter from './pages/SMSCounter';
import SchoolsSMS from './pages/SchoolsSMS';

import Cookies from 'universal-cookie';
import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class App extends Component {
  componentDidMount() {
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isConnected: false,
     isClerk: false
   }
  }


  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({isConnected: true, isClerk: response.data.isClerk});
      }
    });
  }

  render() {
    return (
      <Router>
      {this.state.isConnected ? (
        <div>
          <Switch>
            <Route exact path="/send-sms-students">
              <SchoolsSMS />
            </Route>

            <Route exact path="/sms-counter">
              <SMSCounter />
            </Route>

            <Route exact path="/clerks-sms">
              <ClerksSMS />
            </Route>

            <Route exact path="/teachers-sms">
              <TeachersSMS />
            </Route>

            <Route exact path="/store/edit">
              <EditStore />
            </Route>

            <Route exact path="/store/add">
              <AddStore />
            </Route>

            <Route exact path="/stores">
              <Stores />
            </Route>

            <Route exact path="/guests">
              <Guests />
            </Route>

            <Route exact path="/system-messages">
              <SystemMessages />
            </Route>

            <Route exact path="/categories">
              <Categories />
            </Route>

            <Route exact path="/board">
              <Board />
            </Route>

            <Route exact path="/board/edit">
              <EditBoard />
            </Route>

            <Route exact path="/school">
              <SchoolWatch />
            </Route>

            <Route exact path="/vat">
              <Vat />
            </Route>

            <Route exact path="/school/add">
              <AddSchool />
            </Route>

            <Route exact path="/school/update-smallcash">
              <EditSchoolSmallCash />
            </Route>

            <Route exact path="/school/update-invoices">
              <EditInvoices />
            </Route>

            <Route exact path="/school/update">
              <EditSchool />
            </Route>

            <Route exact path="/manager/update">
              <EditManager />
            </Route>

            <Route exact path="/school/branch/add">
              <AddBranch />
            </Route>

            <Route exact path="/ptors">
              <Ptors />
            </Route>

            <Route exact path="/clerk">
              <AddClerk />
            </Route>

            <Route exact path="/clerk-edit">
              <EditClerk />
            </Route>

            <Route path="/">
              <Dashboard />
            </Route>
          </Switch>
        </div>
      ) : (
        <div>
          <Switch>
            <Route path="/">
              <Login />
            </Route>
          </Switch>
        </div>
      )}
    </Router>
    )
  }
}

export default App;
