import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class AddSchool extends Component {
  componentDidMount() {
    this.getSchools();
  }

  constructor(props) {
   super(props);
   this.state = {
     name: '',
     isBranch: false,
     canAddEmployees: false,
     parentSchoolId: '',
     firstName: '',
     lastName: '',
     idNumber: '',
     email: '',
     phoneNumber: '',
     password: '',

     schools: [],

     address: '',
     postalCode: '',
     schoolEmail: '',
     officialIdNumber: '',
     CPAEmail: '',
     mailAddress: '',
     tranzila_supplier: '',
     tranzila_password: '',
     invoiceNumber: 0,
     receiptTaxInvoiceNumber: 0,
     receiptNumber: 0,
     signupNumberOfAllowedPayments: 0,
     insideTestNumberOfAllowedPayments: 0,
     type: '',
     wavesCommission: false,
     wavesCommissionSignupTimes: 0,
     wavesCommissionInsideTestTimes: 0,
     wavesCommissionSignupFirstPayment: 0,
     wavesCommissionSignupSecondPayment: 0,
     wavesCommissionInsideTestFirstPayment: 0,
     wavesCommissionInsideTestSecondPayment: 0,
     displayPermissionsNumberTesting: false,
     canManagerPayToEmployees: false,
     haveInsideTests: true,
     haveSignUpFee: true,
   }
  }

  handleChange = () => {
    let isBranch = this.state.isBranch ? false : true;

    this.setState({isBranch: isBranch});
  }

  handleHaveInsideTests = () => {
    let haveInsideTests = this.state.haveInsideTests ? false : true;

    this.setState({haveInsideTests: haveInsideTests});
  }

  handleHaveSignUpFee = () => {
    let haveSignUpFee = this.state.haveSignUpFee ? false : true;

    this.setState({haveSignUpFee: haveSignUpFee});
  }

  getSchools = async () => {
    axios.get(API + 'root/schools', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({
          schools: response.data.data.schools,
          parentSchoolId: response.data.data.schools[0]._id
        }, () => {
          console.log(this.state.schools);
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  createSchool = async () => {
    if (this.state.name !== '' && this.state.firstName !== '' && this.state.lastName !== '' && this.state.email !== '') {
      let payload = {
        name: this.state.name,
        schoolParentId: this.state.isBranch ? this.state.parentSchoolId : '',

        firstName: this.state.firstName,
        lastName: this.state.lastName,
        idNumber: this.state.idNumber,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        password: this.state.password,
        canAddEmployees: this.state.canAddEmployees,
        tranzila_supplier: this.state.tranzila_supplier,
        tranzila_password: this.state.tranzila_password,
        type: this.state.type,

        signupNumberOfAllowedPayments: this.state.signupNumberOfAllowedPayments,
        insideTestNumberOfAllowedPayments: this.state.insideTestNumberOfAllowedPayments,

        address: this.state.address,
        postalCode: this.state.postalCode,
        schoolEmail: this.state.schoolEmail,
        officialIdNumber: this.state.officialIdNumber,
        mailAddress: this.state.mailAddress,
        CPAEmail: this.state.CPAEmail,

        invoiceNumber: this.state.invoiceNumber,
        receiptTaxInvoiceNumber: this.state.receiptTaxInvoiceNumber,
        receiptNumber: this.state.receiptNumber,

        wavesCommission: this.state.wavesCommission,
        wavesCommissionSignupTimes: this.state.wavesCommissionSignupTimes,
        wavesCommissionInsideTestTimes: this.state.wavesCommissionInsideTestTimes,
        wavesCommissionSignupFirstPayment: this.state.wavesCommissionSignupFirstPayment,
        wavesCommissionSignupSecondPayment: this.state.wavesCommissionSignupSecondPayment,
        wavesCommissionInsideTestFirstPayment: this.state.wavesCommissionInsideTestFirstPayment,
        wavesCommissionInsideTestSecondPayment: this.state.wavesCommissionInsideTestSecondPayment,
        displayPermissionsNumberTesting: this.state.displayPermissionsNumberTesting,
        haveInsideTests: this.state.haveInsideTests,
        haveSignUpFee: this.state.haveSignUpFee,
        canManagerPayToEmployees: this.state.canManagerPayToEmployees,
      };

      axios.post(API + 'root/manager-school', payload, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert('בית ספר חדש נוצר בהצלחה');
          window.location.href='/';
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    } else {
      alert('חובה למלא את כל שדות החובה');
    }
  }

  handleChangewavesCommission = () => {
    let wavesCommission = this.state.wavesCommission;

    if (wavesCommission) {
      this.setState({wavesCommission: false});
    } else {
      this.setState({wavesCommission: true});
    }
  }

  handleDisplayPermissionsNumberTesting = () => {
    let displayPermissionsNumberTesting = this.state.displayPermissionsNumberTesting;

    if (displayPermissionsNumberTesting) {
      this.setState({displayPermissionsNumberTesting: false});
    } else {
      this.setState({displayPermissionsNumberTesting: true});
    }
  }

  handleCanManagerPayToEmployees = () => {
    let canManagerPayToEmployees = this.state.canManagerPayToEmployees;

    if (canManagerPayToEmployees) {
      this.setState({canManagerPayToEmployees: false});
    } else {
      this.setState({canManagerPayToEmployees: true});
    }
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>הוספת בית ספר חדש</h2>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.haveSignUpFee}
                  onChange={(e) => this.handleHaveSignUpFee()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם קיימת אגרת רישום
              </div>
              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.haveInsideTests}
                  onChange={(e) => this.handleHaveInsideTests()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם קיימים מבחנים פנימיים
              </div>
              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.canManagerPayToEmployees}
                  onChange={(e) => this.handleCanManagerPayToEmployees()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם מנהל יכול לרשום תשלום למורה/מנהל מקצועי
              </div>


              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.name}
                onChange={(e) => this.setState({name: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>כתובת בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.address}
                onChange={(e) => this.setState({address: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>מיקוד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.postalCode}
                onChange={(e) => this.setState({postalCode: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>אי-מייל בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.schoolEmail}
                onChange={(e) => this.setState({schoolEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>אי-מייל רואה חשבון</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.CPAEmail}
                onChange={(e) => this.setState({CPAEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>ח.פ/ע.מ</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.officialIdNumber}
                onChange={(e) => this.setState({officialIdNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>כתובת למשלוח דואר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.mailAddress}
                onChange={(e) => this.setState({mailAddress: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית מס קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.receiptTaxInvoiceNumber}
                onChange={(e) => this.setState({receiptTaxInvoiceNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.invoiceNumber}
                onChange={(e) => this.setState({invoiceNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.receiptNumber}
                onChange={(e) => this.setState({receiptNumber: e.target.value})}
              />


              <p style={{textAlign: 'right'}}>מספר דמי רישום לתלמיד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.signupNumberOfAllowedPayments}
                onChange={(e) => this.setState({signupNumberOfAllowedPayments: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>מספר מ.פ לחיוב תלמיד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.insideTestNumberOfAllowedPayments}
                onChange={(e) => this.setState({insideTestNumberOfAllowedPayments: e.target.value})}
              />

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.wavesCommission}
                  onChange={(e) => this.handleChangewavesCommission()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם מתבצע פיצול אגרות
              </div>

              {this.state.wavesCommission && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}>מספר אגרות רישום ראשוני (עד תשלום מלא)</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionSignupTimes}
                  onChange={(e) => this.setState({wavesCommissionSignupTimes: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>מספר אגרות מבחן פנימי ראשוני (עד תשלום מלא)</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionInsideTestTimes}
                  onChange={(e) => this.setState({wavesCommissionInsideTestTimes: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות רישום ראשוני</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionSignupFirstPayment}
                  onChange={(e) => this.setState({wavesCommissionSignupFirstPayment: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות רישום מלא</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionSignupSecondPayment}
                  onChange={(e) => this.setState({wavesCommissionSignupSecondPayment: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות מ.פ ראשוני</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionInsideTestFirstPayment}
                  onChange={(e) => this.setState({wavesCommissionInsideTestFirstPayment: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות מ.פ מלא</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionInsideTestSecondPayment}
                  onChange={(e) => this.setState({wavesCommissionInsideTestSecondPayment: e.target.value})}
                />
                </React.Fragment>
              )}

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.isBranch}
                  onChange={(e) => this.handleChange()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם חלק מרשת
              </div>

              {this.state.isBranch && (
                <React.Fragment>
                  <p style={{textAlign: 'right', fontWeight: 'bold'}}>בחר את שם הרשת מתוך הרשימה</p>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.parentSchoolId}
                    onChange={(e) => this.setState({parentSchoolId: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    {this.state.schools.map((school) => {
                      if (!school.isBranch) {
                        return (
                          <MenuItem value={school._id} style={{direction: 'rtl', textAlign: 'right'}}>{school.name}</MenuItem>
                        )
                      }
                    })}
                  </Select>
                </React.Fragment>
              )}

              <h3 style={{textAlign: 'right'}}>הוספת מנהל/ת</h3>

              <p style={{textAlign: 'right'}}>משתמש מעורב</p>
              <Select
                value={this.state.type}
                onChange={(e) => this.setState({type: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'teacher'}>מורה</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'professional'}>מנהל מקצועי</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'professionalteacher'}>מורה / מנהל מקצועי</MenuItem>
                <MenuItem style={{textAlign: 'right', direction: 'rtl'}} value={'clerk'}>פקיד/ה</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם פרטי</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.firstName}
                onChange={(e) => this.setState({firstName: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם משפחה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.lastName}
                onChange={(e) => this.setState({lastName: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>ת.ז</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.idNumber}
                onChange={(e) => this.setState({idNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> אימייל</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.email}
                onChange={(e) => this.setState({email: e.target.value})}
                required
              />

              <p style={{textAlign: 'right'}}>מספר טלפון</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.phoneNumber}
                onChange={(e) => this.setState({phoneNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סיסמה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />

              <p style={{textAlign: 'right', fontWeight: 'bold', direction: 'rtl'}}>האם יכול להוסיף משתמשים?</p>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={this.state.canAddEmployees}
                onChange={(e) => this.setState({canAddEmployees: e.target.value})}
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
              >
                  <MenuItem value={true} style={{direction: 'rtl', textAlign: 'right'}}>כן</MenuItem>
                  <MenuItem value={false} style={{direction: 'rtl', textAlign: 'right'}}>לא</MenuItem>
              </Select>

              <p style={{textAlign: 'right'}}>שם המסוף</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.tranzila_supplier}
                onChange={(e) => this.setState({tranzila_supplier: e.target.value})}
              />
              <p style={{textAlign: 'right'}}>סיסמת זיכויים / ביטולים של המסוף</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.tranzila_password}
                onChange={(e) => this.setState({tranzila_password: e.target.value})}
              />


              <Button onClick={(e) => this.createSchool()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                להוספה
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddSchool;
