import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

const rows = [
  { adminName: 'ליאור', name: 'אורט נהיגה', branchName: '-', isBranch: 'לא', schoolsInBranch: 0, protein: 4.0 },
  { adminName: 'משה', name: 'בליך שיעורי נהיגה', branchName:  '-', isBranch: 'לא', schoolsInBranch: 0, protein: 4.3 },
  { adminName: 'יצחק', name: 'קורקו לוקו', branchName: '-', isBranch: 'כן', schoolsInBranch: 1 },
  { adminName: 'שמשון', name: 'קורקו לוקו ראשון לציון', branchName: 'קורקו לוקו', isBranch: 'כן', schoolsInBranch: 1, protein: 6.0 },
  { adminName: 'דויד', name: 'מונגו בנגו נהיגה', branchName: '-', isBranch: 'לא', schoolsInBranch: 0 },
];

class SchoolWatch extends Component {

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <h3>בית ספר אורט נהיגה</h3>

              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     <TableCell align="right">נתוני בית הספר</TableCell>
                     <TableCell align="right">הוספת סניף</TableCell>
                     <TableCell align="right">עריכת המנהל/ת</TableCell>
                     <TableCell align="right">עריכת בית הספר</TableCell>
                     <TableCell align="right">מספר הסניפים ברשת</TableCell>
                     <TableCell align="right">שם הרשת</TableCell>
                     <TableCell align="right">האם סניף ברשת</TableCell>
                     <TableCell align="right">מספר תלמידים</TableCell>
                     <TableCell align="right">מספר מורים מקצועיים</TableCell>
                     <TableCell align="right">מספר מורים</TableCell>
                     <TableCell align="right">שם הבית ספר</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {rows.map((row) => (
                     <TableRow>
                       <TableCell align="right">
                         <Button variant="ghost">
                           <VisibilityIcon />
                         </Button>
                       </TableCell>
                       <TableCell align="right">
                         <Button variant="ghost">
                           <AddCircleOutlineIcon />
                         </Button>
                       </TableCell>
                       <TableCell align="right">
                         <Button variant="ghost">
                          <FaceIcon />
                         </Button>
                       </TableCell>
                       <TableCell align="right">
                         <Button variant="ghost">
                          <EditIcon />
                         </Button>
                       </TableCell>
                       <TableCell align="right">{row.schoolsInBranch}</TableCell>
                       <TableCell align="right">{row.branchName}</TableCell>
                       <TableCell align="right">{row.isBranch}</TableCell>
                       <TableCell align="right">{row.adminName}</TableCell>
                       <TableCell align="right">{row.name}</TableCell>
                       <TableCell align="right">{row.name}</TableCell>
                       <TableCell align="right">{row.name}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
           </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SchoolWatch;
