import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Ptors extends Component {
  componentDidMount() {
    this.getPtors();
  }

  constructor(props) {
   super(props);
   this.state = {
     ptors: [],
     reason: ''
   }
  }

  getPtors = () => {
    axios.get(API + 'root/ptors', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({ptors: response.data.data.ptors});
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  createPtor = (ptor) => {
    axios.post(API + 'root/ptor', {
      reason: ptor
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({reason: ''})
        this.getPtors();
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  removePtor = (id) => {
    axios.delete(API + 'root/ptor?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getPtors();
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>פטורים</h2>

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סיבת פטור</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.reason}
                onChange={(e) => this.setState({reason: e.target.value})}
              />

              <Button onClick={(e) => this.createPtor(this.state.reason)} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                הוספת סיבת פטור חדשה
              </Button>
              <br /><br />
              {this.state.ptors.length > 0 && (
                <table style={{borderCollapse: 'collapse', width: '100%'}}>
                  <tr style={{border: '1px solid black', padding: 8}}>
                    <th>מחק</th>
                    <th style={{border: '1px solid black', padding: 8}}>פטור</th>
                  </tr>
                  {this.state.ptors.reverse().map((index) => {
                    return (
                      <tr style={{border: '1px solid black', padding: 8}}>
                        <th style={{border: '1px solid black', padding: 8}}><Button onClick={(e) => this.removePtor(index._id)}>מחק סיבת פטור</Button></th>
                        <th style={{border: '1px solid black', padding: 8}}>{index.reason}</th>
                      </tr>
                    );
                  })}
                </table>
              )}

            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Ptors;
