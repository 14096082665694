import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Board extends Component {
  componentDidMount () {
    this.getMessages();
    this.getAllMessages();
    this.getCategories();
    this.validate();

    this.getAllCategoriesOrders();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: false,
     parentSchoolId: 10,
     frozen: false,
     canAddEmployees: false,
     type: '',
     messages: [],
     messagesUser: [],
     messagesOriginal: [],
     board: [],
     boardOriginal: [],
     string: '',
     addBtncolor: '',
     showAddBtncolor: false,
     color: '',
     fontColor: '',
     categories: [],
     children_categories: [],
     categoriesChildren: [],
     showAddColorMessage: false,
     messageColor: '',
     messageFontColor: '',
     showOrder: false,

     isClerk: true,
     viewBoard: false,
     deleteBoard: false,
     approveBoard: false,
     updateBoard: false,
     viewCategories: false,
     categoriesColors: false,
     manageGuests: false,
     deleteGuests: false,
     systemMessages: false,
     deleteSystemMessages: false,
     soldColor: '#ffffff',
     soldBackgroundColor: '#ffffff',

     subCategoriesOrder: [],

     emails: []
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  handleChangeComplete = (color) => {
    this.setState({ color: color.hex });
  };

  handleChangeCompleteFont = (color) => {
    this.setState({ fontColor: color.hex });
  };

  handleChangeMessageColor = (color) => {
    this.setState({ messageColor: color.hex });
  };

  handleMessageFontColor = (color) => {
    this.setState({ messageFontColor: color.hex });
  };

  handleSoldBackgroundColor = (color) => {
    this.setState({ soldBackgroundColor: color.hex });
  };

  handleSoldColor = (color) => {
    this.setState({ soldColor: color.hex });
  };

  getAllCategoriesOrders = () => {
    axios.get(API + 'root/categories-orders', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({subCategoriesOrder: response.data.data.children}, () => {
          this.changeOrderDB();
        });
      }
    }).catch(err => {
      console.log(err);
    });
  }

  getMessages = () => {
    axios.get(API + 'root/sivanboardmessages-to-approve', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messages: response.data.data.sivanboardmessage,
          messagesUser: response.data.data.sivanboardmessageusers,
          messagesOriginal: response.data.data.sivanboardmessage,
        }, () => {
          this.state.messages.sort(function(a, b){
            return a.order > b.order;
          });

          this.state.messagesOriginal.sort(function(a, b){
            return a.order > b.order;
          });

          this.state.messagesUser.sort(function(a, b){
            return a.order > b.order;
          });

          this.setState({
            messages: this.state.messages,
            messagesOriginal: this.state.messagesOriginal,
            messagesUser: this.state.messagesUser
          });
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  parser = (categories, str) => {
    str = str.split(',');
    let output = [];
    str.forEach((item, i) => {
      console.log(item);
      categories.forEach((category) => {
        console.log(category);
        if (item === category.name.trim()) {
          output.push(category);
        }
      });
    });

    this.setState({categories: output});
  }


  changeSoldColors = () => {
    axios.put(API + 'root/sivanboardmessage-sold-color', {
      color: this.state.soldBackgroundColor,
      fontColor: this.state.soldColor
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getCategories();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeButtonColors = () => {
    axios.put(API + 'root/sivanboardmessage-add-button-color', {
      color: this.state.color,
      fontColor: this.state.fontColor
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getCategories();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeMessageColors = () => {
    axios.put(API + 'root/sivanboardmessage-background', {
      fontColor: this.state.messageFontColor,
      messageColor: this.state.messageColor
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      this.getMessageColors();
    })
    .catch(error => {
      console.log(error);
    });
  }


  getMessageColors = () => {
    axios.get(API + 'root/sivanboardmessage-background', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          messageColor: response.data.data.globalsetting.value
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeAddButtonFontColor = () => {
    axios.put(API + 'root/sivanboardmessage-add-button-font-color', {
      color: this.state.color
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getCategories();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  getAllMessages = () => {
    axios.get(API + 'root/sivanboardmessage-all', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          board: response.data.data.board,
          boardOriginal: response.data.data.board,
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  approve = (id) => {
    axios.put(API + 'root/sivanboardmessage/approve?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  approveUser = (id) => {
    axios.put(API + 'root/sivanboardmessage/approve-user?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  decline = (id) => {
    axios.put(API + 'root/sivanboardmessage/decline?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  search = (string) => {
    let messages = this.state.boardOriginal;
    let output = [];

    messages.forEach((item, i) => {
      if (item.freetext.includes(string)) output.push(item);
      else if (item.userName.includes(string)) output.push(item);
      else if (item.priceWanted.toString().includes(string)) output.push(item);
      else {

      }
    });

    output.sort(function(a, b){
      return a.order > b.order;
    });

    this.setState({board: output});
  }

  reset = () => {
    this.setState({board: this.state.boardOriginal, string: ''})
  }

  soldNotSold = (id) => {
    axios.put(API + 'root/sivanboardmessage/soldNotSold?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  approveNotApprove = (id) => {
    axios.put(API + 'root/sivanboardmessage/approveNotApprove?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }


  orderToTop = (id) => {
    axios.put(API + 'root/sivanboardmessage/orderToTop?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  orderDown = (id) => {
    axios.put(API + 'root/sivanboardmessage/orderDown?id='+id, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getMessages();
        this.getAllMessages();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  getCategories = () => {
    axios.get(API + 'root/sivanboardcategories', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        let parent = [];
        let children = {};

        let categories = response.data.data.categories;

        categories.map((category) => {
          if (category.parentCategory === '' || category.parentCategory === null || category.parentCategory === undefined) parent.push(category);
          else {
            if (children[category.parentCategory] === undefined) children[category.parentCategory] = [];
            children[category.parentCategory].push(category);
          }
        });
        // this.setState({categories: parent, children_categories: children});
        this.setState({children_categories: children});

        axios.get(API + 'root/sivanboardmessage-globalSettings', {
          headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
        })
        .then(response => {
          console.log(response);
          this.setState({
            color: response.data.data.color,
            messageColor: response.data.data.messageColor,
            fontColor: response.data.data.fontColor,
            messageFontColor: response.data.data.messageFontColor,
            categoriesOrder: this.parser(parent, response.data.data.categoriesOrder),
            soldColor: response.data.data.soldColor,
            soldBackgroundColor: response.data.data.soldBackgroundColor,
          });
        }).catch(error => {
          console.log(error);
        });
        // this.setState({
        //   categories: response.data.data.categories
        // }, () => {
        //
        // });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeOrder = (name, tt) => {
    axios.put(API + 'root/category-order?name='+name+'&tt='+tt, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      this.getCategories();
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeOrderDB = (name, tt) => {
    axios.get(API + 'root/categories-orders-db', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      this.setState({subCategoriesOrder: response.data.data.children})
    })
    .catch(error => {
      console.log(error);
    });
  }

  subChangeOrder = (name, parent, tt) => {
    axios.put(API + 'root/category-order-sub?name='+name+'&tt='+tt+'&parent='+parent, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      this.changeOrderDB();
    })
    .catch(error => {
      console.log(error);
    });
  }

  chooseCategory = (category) => {
  this.setState({chosenCategory: category}, () => {
    let messages = this.state.boardOriginal;
    let output = [];

    messages.forEach((item, i) => {
      console.log(item);
      if (item.category === category) {
        output.push(item);
      }
    });

    this.setState({board: output, categoriesChildren: this.state.children_categories[category]});
  })
}


chooseChildCategory = (category) => {
    let messages = this.state.boardOriginal;
    let output = [];

    messages.forEach((item, i) => {
      if (item.category === this.state.chosenCategory && item.childCategory === category) {
        output.push(item);
      }
    });

    this.setState({board: output});

}


filterAllSold = () => {
  let messages = this.state.boardOriginal;
  let output = [];

  messages.forEach((item, i) => {
    if (item.sold) output.push(item);
  });

  this.setState({board: output});
}

filterAllNotSold = () => {
  let messages = this.state.boardOriginal;
  let output = [];

  messages.forEach((item, i) => {
    if (!item.sold) output.push(item);
  });

  this.setState({board: output});
}

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>

            {((this.state.isClerk && this.state.viewCategories) || !this.state.isClerk) && (
              <React.Fragment>
              <h3 style={{textAlign: 'right'}}>שנה סדר קטגוריות</h3>
              <div style={{textAlign: 'right'}}>
              {!this.state.showOrder ? (
                <React.Fragment>
                <h4>לחץ על קטגוריה על מנת להזיזה אלמנט אחד שמאלה</h4>
                <center><div style={{textAlign: 'center'}}>
                  {this.state.categories.map((index) => {
                    return (
                      <React.Fragment>
                        {(index.color === '' || index.color === null || index.color === undefined) ? (
                          <Button variant={'contained'} onClick={(e) => this.changeOrder(index.name, 'left')} color={index.name === this.state.chooseCategory ? 'primary' : 'secondary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', color: index.fontColor}}>{index.name}</Button>
                        ) : (
                          <Button variant={'contained'} onClick={(e) => this.changeOrder(index.name, 'left')}  style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', backgroundColor: index.color, color: 'white', color: index.fontColor}}>{index.name}</Button>
                        )}
                      </React.Fragment>
                    )
                  })}
                </div></center>

                {this.state.subCategoriesOrder.map((index) => {
                  return (
                    <center><div style={{textAlign: 'center'}}>
                      <p>קטגוריית אב: {index[0]}</p>
                      {index[1].split(',').map((row) => {
                        return (
                          <React.Fragment>
                            {(row.color === '' || row.color === null || row.color === undefined) ? (
                              <Button variant={'contained'} onClick={(e) => this.subChangeOrder(row, index[0], 'left')} color={'primary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', color: row.fontColor}}>{row}</Button>
                            ) : (
                              <Button variant={'contained'} onClick={(e) => this.subChangeOrder(row, index[0], 'left')}  style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%'}}>{row}</Button>
                            )}
                          </React.Fragment>
                        )
                      })}
                    </div></center>
                  )
                })}
                <br />
                <center>
                {/*<table>
                  <tr style={{border: '1px solid black'}}>
                    <th style={{textAlign: 'center', border: '1px solid black'}}>שמאלה</th>
                    <th style={{textAlign: 'center', border: '1px solid black'}}>קטגוריה</th>
                    <th style={{textAlign: 'center', border: '1px solid black'}}>ימינה</th>
                  </tr>

                  {this.state.categories.map((item) => {
                    return (
                      <tr style={{border: '1px solid black'}}>
                        <td style={{textAlign: 'center', border: '1px solid black'}}><Button onClick={(e) => this.changeOrder(item.name, 'left')}>הזז</Button></td>
                        <td style={{textAlign: 'center', border: '1px solid black'}}>{item.name}</td>
                        <td style={{textAlign: 'center', border: '1px solid black'}}><Button onClick={(e) => this.changeOrder(item.name, 'right')}>הזז</Button></td>
                      </tr>
                    )
                  })}

                </table>*/}
                </center>
                </React.Fragment>
              ) : (
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showOrder: true})}>הצג סדר קטגוריות</Button>
              )}
              </div>
              </React.Fragment>
              )}

              {((this.state.isClerk && this.state.categoriesColors) || !this.state.isClerk) && (
              <React.Fragment>
              <h3 style={{textAlign: 'right'}}>צבע כפתור הוספת מודעה</h3>
              <div style={{textAlign: 'right'}}>
              {this.state.showAddBtncolor ? (
                <React.Fragment>
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showAddBtncolor: false})}>הסתר בחירת צבע</Button>
                <div style={{textAlign: 'right', display: 'grid'}}>
                <br/>
                <p style={{direction: 'rtl'}}>רקע:</p>
                <ChromePicker
                  color={this.state.color}
                  onChangeComplete={this.handleChangeComplete}
                  style={{textAlign: 'right', display: 'grid'}}
                /><br/>
                <p style={{direction: 'rtl'}}>פונט:</p>
                <ChromePicker
                  color={this.state.fontColor}
                  onChangeComplete={this.handleChangeCompleteFont}
                  style={{textAlign: 'right', display: 'grid'}}
                /><br/>
                </div>
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.changeButtonColors()}>שמור</Button>
                </React.Fragment>
              ) : (
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showAddBtncolor: true})}>הצג בחירת צבע</Button>
              )}
              </div>
              <h3 style={{textAlign: 'right'}}>צבע מודעות ופונט מודעות</h3>
              <div style={{textAlign: 'right'}}>
              {this.state.showAddColorMessage ? (
                <React.Fragment>
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showAddColorMessage: false})}>הסתר בחירת צבע</Button>
                <div style={{textAlign: 'right', display: 'grid'}}>
                <br/>
                <p style={{direction: 'rtl'}}>רקע:</p>
                <ChromePicker
                  color={this.state.messageColor}
                  onChangeComplete={this.handleChangeMessageColor}
                  style={{textAlign: 'right', display: 'grid'}}
                /><br/>
                <p style={{direction: 'rtl'}}>פונט:</p>
                <ChromePicker
                  color={this.state.messageFontColor}
                  onChangeComplete={this.handleMessageFontColor}
                  style={{textAlign: 'right', display: 'grid'}}
                /><br/>
                </div>
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.changeMessageColors()}>שמור</Button>
                </React.Fragment>
              ) : (
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showAddColorMessage: true})}>הצג בחירת צבע</Button>
              )}
              </div>
              <h3 style={{textAlign: 'right', direction: 'rtl'}}>צבע "נמכר"</h3>
              <div style={{textAlign: 'right'}}>
              {this.state.showSoldColor ? (
                <React.Fragment>
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showSoldColor: false})}>הסתר בחירת צבע</Button>
                <div style={{textAlign: 'right', display: 'grid'}}>
                <br/>
                <p style={{direction: 'rtl'}}>רקע:</p>
                <ChromePicker
                  color={this.state.soldBackgroundColor}
                  onChangeComplete={this.handleSoldBackgroundColor}
                  style={{textAlign: 'right', display: 'grid'}}
                /><br/>
                <p style={{direction: 'rtl'}}>פונט:</p>
                <ChromePicker
                  color={this.state.soldColor}
                  onChangeComplete={this.handleSoldColor}
                  style={{textAlign: 'right', display: 'grid'}}
                /><br/>
                </div>
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.changeSoldColors()}>שמור</Button>
                </React.Fragment>
              ) : (
                <Button color={'primary'} variant={'outlined'} onClick={(e) => this.setState({showSoldColor: true})}>הצג בחירת צבע</Button>
              )}
              </div>

              </React.Fragment>
              )}
              <hr />

              {((this.state.isClerk && this.state.approveBoard) || !this.state.isClerk) && (
              <React.Fragment>
              <h2 style={{textAlign: 'right'}}>לוח המודעות - לאישור</h2>
              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     <TableCell align="right">לביטול</TableCell>
                     <TableCell align="right">לעריכה</TableCell>
                     <TableCell align="right">לאישור</TableCell>
                     <TableCell align="right">תמונה</TableCell>
                     <TableCell align="right">האם קיים תוקף למוצר</TableCell>
                     <TableCell align="right">תוקף המוצר</TableCell>
                     <TableCell align="right">מחיר מבוקש</TableCell>
                     <TableCell align="right">מחיר</TableCell>
                     <TableCell align="right">טקסט חופשי</TableCell>
                     <TableCell align="right">שם המפרסם</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {this.state.messages.map((index, i) => (
                     <TableRow key={i}>
                       <TableCell align="right"><Button onClick={(e) => this.decline(index._id)}>ביטול</Button></TableCell>
                       <TableCell align="right"><Link to={'/board/edit?id='+index._id} style={{textDecoration: 'none'}}><Button>עריכה</Button></Link></TableCell>
                       <TableCell align="right"><Button onClick={(e) => this.approve(index._id)}>אישור</Button></TableCell>
                       <TableCell align="right"><img src={'https://sivan-school.com/images/' + index.picture} style={{width: 72, height: 72}}/></TableCell>
                       <TableCell align="right">{index.isLastMinute ? 'כן' : 'לא'}</TableCell>
                       <TableCell align="right">{(index.isLastMinute) ? (new Date(index.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(index.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(index.lastMinuteMaxDate).getFullYear()) : '-'}</TableCell>
                       <TableCell align="right">{index.priceWanted}</TableCell>
                       <TableCell align="right">{index.price}</TableCell>
                       <TableCell align="right">{index.freetext}</TableCell>
                       <TableCell align="right">{index.userName}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
             </React.Fragment>
            )}
              {/*<table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לביטול</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לעריכה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לאישור</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>תמונה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם קיים תוקף למוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>תוקף המוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיר מבוקש</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>טקסט חופשי</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם המפרסם</th>
                </tr>
                {this.state.messages.map((index) => {
                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.decline(index._id)}>ביטול</Button></td>
                      <Link to={'/board/edit?id='+index._id} style={{textDecoration: 'none'}}><td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button>עריכה</Button></td></Link>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.approve(index._id)}>אישור</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><img src={'https://sivan-school.com/images/' + index.picture} style={{width: 72, height: 72}}/></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.isLastMinute ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{(index.isLastMinute) ? (new Date(index.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(index.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(index.lastMinuteMaxDate).getFullYear()) : '-'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.priceWanted}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.price}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.freetext}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.userName}</td>
                    </tr>
                  );
                })}
              </table>*/}
              {((this.state.isClerk && this.state.manageGuests && this.state.approveBoard) || !this.state.isClerk) && (
                <React.Fragment>
                <h2 style={{textAlign: 'right'}}>לוח המודעות - לאישור (משתמשים חדשים)</h2>
                <TableContainer>
                 <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                   <TableHead>
                     <TableRow>
                       <TableCell align="right">לביטול</TableCell>
                       <TableCell align="right">לעריכה</TableCell>
                       <TableCell align="right">לאישור</TableCell>
                       <TableCell align="right">תמונה</TableCell>
                       <TableCell align="right">האם קיים תוקף למוצר</TableCell>
                       <TableCell align="right">תוקף המוצר</TableCell>
                       <TableCell align="right">מחיר מבוקש</TableCell>
                       <TableCell align="right">מחיר</TableCell>
                       <TableCell align="right">טקסט חופשי</TableCell>
                       <TableCell align="right">מספר טלפון</TableCell>
                       <TableCell align="right">אימייל</TableCell>
                       <TableCell align="right">שם המפרסם</TableCell>
                     </TableRow>
                   </TableHead>
                   <TableBody>
                     {this.state.messagesUser.map((index, i) => (
                       <TableRow key={i+'_messagesuer'}>
                         <TableCell align="right"><Button onClick={(e) => this.decline(index._id)}>ביטול</Button></TableCell>
                         <TableCell align="right"><Link to={'/board/edit?id='+index._id} style={{textDecoration: 'none'}}><Button>עריכה</Button></Link></TableCell>
                         <TableCell align="right"><Button onClick={(e) => this.approveUser(index._id)}>אישור</Button></TableCell>
                         <TableCell align="right"><img src={'https://sivan-school.com/images/' + index.picture} style={{width: 72, height: 72}}/></TableCell>
                         <TableCell align="right">{index.isLastMinute ? 'כן' : 'לא'}</TableCell>
                         <TableCell align="right">{(index.isLastMinute) ? (new Date(index.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(index.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(index.lastMinuteMaxDate).getFullYear()) : '-'}</TableCell>
                         <TableCell align="right">{index.priceWanted}</TableCell>
                         <TableCell align="right">{index.price}</TableCell>
                         <TableCell align="right">{index.freetext}</TableCell>
                         <TableCell align="right">{index.phoneNumber}</TableCell>
                         <TableCell align="right">{index.email}</TableCell>
                         <TableCell align="right">{index.userName}</TableCell>
                       </TableRow>
                     ))}
                   </TableBody>
                 </Table>
               </TableContainer>
               </React.Fragment>
              )}
              {/*<table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לביטול</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לעריכה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לאישור</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>תמונה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם קיים תוקף למוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>תוקף המוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיר מבוקש</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>טקסט חופשי</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מספר טלפון</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>אימייל</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם המפרסם</th>
                </tr>
                {this.state.messagesUser.map((index) => {
                  console.log(index);
                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.decline(index._id)}>ביטול</Button></td>
                      <Link to={'/board/edit?id='+index._id} style={{textDecoration: 'none'}}><td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button>עריכה</Button></td></Link>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.approveUser(index._id)}>אישור</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><img src={'https://sivan-school.com/images/' + index.picture} style={{width: 72, height: 72}}/></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.isLastMinute ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{(index.isLastMinute) ? (new Date(index.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(index.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(index.lastMinuteMaxDate).getFullYear()) : '-'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.priceWanted}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.price}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.freetext}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.phoneNumber}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.email}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.userName}</td>
                    </tr>
                  );
                })}
              </table>*/}

              <h2 style={{textAlign: 'right'}}>לוח המודעות - כל המודעות</h2>

              <h4 style={{textAlign: 'center'}}>פילטר</h4>
              <center><div style={{textAlign: 'center'}}>
                {this.state.categories.map((index) => {
                  return (
                    <React.Fragment>
                      {(index.color === '' || index.color === null || index.color === undefined) ? (
                        <Button onClick={(e) => this.chooseCategory(index.name)} variant={'contained'} color={index.name === this.state.chooseCategory ? 'primary' : 'secondary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', color: index.fontColor}}>{index.name}</Button>
                      ) : (
                        <Button onClick={(e) => this.chooseCategory(index.name)} variant={'contained'}  style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%', backgroundColor: index.color, color: 'white', color: index.fontColor}}>{index.name}</Button>
                      )}
                    </React.Fragment>
                  )
                })}
                <Button onClick={(e) => this.filterAllSold()} variant={'contained'} color={'secondary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%',}}>מודעות שנמכרו</Button>
                <Button onClick={(e) => this.filterAllNotSold()} variant={'contained'} color={'primary'} style={{borderRadius: 20, marginRight: '0.5%', marginLeft: '0.5%',}}>מודעות שלא נמכרו</Button>
              </div></center>
              <br/><br/>

              <Button color={'secondary'} variant={'contained'} onClick={(e) => this.reset()}>איפוס</Button>
              &nbsp;&nbsp;
              <Button color={'primary'} variant={'contained'} onClick={(e) => this.search(this.state.string)}>חיפוש</Button>
              &nbsp;&nbsp;&nbsp;&nbsp;
              <TextField
                value={this.state.string}
                onChange={(e) => this.setState({string: e.target.value})}
              />


              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     <TableCell align="right">העלה למקום ראשון</TableCell>
                     <TableCell align="right">הורד מקום בסדר</TableCell>
                     {((this.state.isClerk && this.state.approveBoard) || !this.state.isClerk) && (<TableCell align="right">החזרה למאגר הלא מאושרים</TableCell>)}
                     <TableCell align="right">שנה סטטוס מכירה</TableCell>
                     {((this.state.isClerk && this.state.deleteBoard) || !this.state.isClerk) && (<TableCell align="right">למחיקה</TableCell>)}
                     <TableCell align="right">לעריכה</TableCell>
                     <TableCell align="right">תמונה</TableCell>
                     <TableCell align="right">האם קיים תוקף למוצר</TableCell>
                     <TableCell align="right">תוקף המוצר</TableCell>
                     <TableCell align="right">מחיר</TableCell>
                     <TableCell align="right">טקסט חופשי</TableCell>
                     <TableCell align="right">שם המפרסם</TableCell>
                     <TableCell align="right">מקום בסדר</TableCell>
                     <TableCell align="right">כמות בקשות ליצירת קשר</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {this.state.board.reverse().map((index, i) => (
                     <TableRow key={i+'_board'}>
                       <TableCell align="right">{<Button onClick={(e) => this.orderToTop(index._id)}>העלה למקום ראשון</Button>}</TableCell>
                       <TableCell align="right">{<Button onClick={(e) => this.orderDown(index._id)}>הורד מקום בסדר</Button>}</TableCell>
                       {((this.state.isClerk && this.state.approveBoard) || !this.state.isClerk) && (<TableCell align="right">{<Button onClick={(e) => this.approveNotApprove(index._id)}>אושר\לא אושר</Button>}</TableCell>)}
                       <TableCell align="right">{index.sold ? (<Button onClick={(e) => this.soldNotSold(index._id)}>סמן כלא נמכר</Button>) : <Button onClick={(e) => this.soldNotSold(index._id)}>סמן כנמכר</Button>}</TableCell>
                       {((this.state.isClerk && this.state.deleteBoard) || !this.state.isClerk) && (<TableCell align="right"><Button onClick={(e) => this.decline(index._id)}>מחיקה</Button></TableCell>)}
                       <TableCell align="right"><Link to={'/board/edit?id='+index._id} style={{textDecoration: 'none'}}><Button>עריכה</Button></Link></TableCell>
                       <TableCell align="right"><img src={'https://sivan-school.com/images/' + index.picture} style={{width: 72, height: 72}}/></TableCell>
                       <TableCell align="right">{index.isLastMinute ? 'כן' : 'לא'}</TableCell>
                       <TableCell align="right">{(index.isLastMinute) ? (new Date(index.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(index.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(index.lastMinuteMaxDate).getFullYear()) : '-'}</TableCell>
                       <TableCell align="right">{index.price}</TableCell>
                       <TableCell align="right">{index.freetext}</TableCell>
                       <TableCell align="right">{index.userName}</TableCell>
                       <TableCell align="right">{index.order}</TableCell>
                       <TableCell align="right">{(index.requestedContact === undefined || index.requestedContact === null || index.requestedContact === '' || isNaN(index.requestedContact)) ? 0 : index.requestedContact}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>

            {/*<table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>העלה למקום ראשון</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>הורד מקום בסדר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>החזרה למאגר הלא מאושרים</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שנה סטטוס מכירה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>למחיקה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לעריכה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>תמונה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>האם קיים תוקף למוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>תוקף המוצר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיר מבוקש</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מחיר</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>טקסט חופשי</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם המפרסם</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מקום בסדר</th>
                </tr>
                {this.state.board.reverse().map((index) => {
                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{<Button onClick={(e) => this.orderToTop(index._id)}>העלה למקום ראשון</Button>}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{<Button onClick={(e) => this.orderDown(index._id)}>הורד מקום בסדר</Button>}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{<Button onClick={(e) => this.approveNotApprove(index._id)}>אושר\לא אושר</Button>}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.sold ? (<Button onClick={(e) => this.soldNotSold(index._id)}>סמן כלא נמכר</Button>) : <Button onClick={(e) => this.soldNotSold(index._id)}>סמן כנמכר</Button>}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.decline(index._id)}>מחיקה</Button></td>
                      <Link to={'/board/edit?id='+index._id} style={{textDecoration: 'none'}}><td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button>עריכה</Button></td></Link>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><img src={'https://sivan-school.com/images/' + index.picture} style={{width: 72, height: 72}}/></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.isLastMinute ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{(index.isLastMinute) ? (new Date(index.lastMinuteMaxDate).getDate() + '/' + parseInt(new Date(index.lastMinuteMaxDate).getMonth() + 1) + '/' + new Date(index.lastMinuteMaxDate).getFullYear()) : '-'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.priceWanted}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.price}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.freetext}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.userName}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.order}</td>
                    </tr>
                  );
                })}
              </table>*/}
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Board;
