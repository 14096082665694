import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import Switch from '@material-ui/core/Switch';


import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditSchool extends Component {
  componentDidMount() {
    this.getSchoolFromQuery();
    this.getSchools();
  }

  constructor(props) {
   super(props);
   this.state = {
     schoolId: '',
     school: {},
     schoolName: '',
     isBranch: false,
     parentSchoolId: '',

     schools: [],
     children: 0,

     address: '',
     postalCode: '',
     schoolEmail: '',
     officialIdNumber: '',
     mailAddress: '',
     CPAEmail: '',
     tranzila_supplier: '',
     tranzila_password: '',

     invoiceNumber: 0,
     receiptTaxInvoiceNumber: 0,
     receiptNumber: 0,
     signupNumberOfAllowedPayments: 0,
     insideTestNumberOfAllowedPayments: 0,

     frozen: false,

     wavesCommission: false,
     wavesCommissionSignupTimes: 0,
     wavesCommissionInsideTestTimes: 0,
     wavesCommissionSignupFirstPayment: 0,
     wavesCommissionSignupSecondPayment: 0,
     wavesCommissionInsideTestFirstPayment: 0,
     wavesCommissionInsideTestSecondPayment: 0,
     displayPermissionsNumberTesting: false,
     canManagerPayToEmployees: false,
     haveInsideTests: false,
     haveSignUpFee: false,
     reason: '',
     ptors: [],
     requireInvoiceNumber: false,
     invoiceNumber: false,

     ptorActive: false,
     ptorChosen: {},
     teacherReceiveComission: false,
     signupFeeText: 'דמי רישום'
   }
  }


    handleHaveInsideTests = () => {
      let haveInsideTests = this.state.haveInsideTests ? false : true;

      this.setState({haveInsideTests: haveInsideTests});
    }

    handleHaveSignUpFee = () => {
      let haveSignUpFee = this.state.haveSignUpFee ? false : true;

      this.setState({haveSignUpFee: haveSignUpFee});
    }

  getSchools = async () => {
    axios.get(API + 'root/schools', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({
          schools: response.data.data.schools,
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getSchoolFromQuery = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/school?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            school: response.data.data.schools[0],
            schoolName: response.data.data.schools[0].name,
            isBranch: response.data.data.schools[0].isBranch,
            parentSchoolId: response.data.data.schools[0].parentId,
            address: response.data.data.schools[0].address,
            postalCode: response.data.data.schools[0].postalCode,
            schoolEmail: response.data.data.schools[0].schoolEmail,
            officialIdNumber: response.data.data.schools[0].officialIdNumber,
            displayPermissionsNumberTesting: response.data.data.schools[0].displayPermissionsNumberTesting,
            mailAddress: response.data.data.schools[0].mailAddress,
            invoiceNumber: response.data.data.schools[0].invoiceNumber,
            receiptTaxInvoiceNumber: response.data.data.schools[0].receiptTaxInvoiceNumber,
            receiptNumber: response.data.data.schools[0].receiptNumber,
            CPAEmail: response.data.data.schools[0].CPAEmail,
            isBranch: response.data.data.schools[0].isBranch,
            children: response.data.data.children,
            frozen: response.data.data.schools[0].frozen,
            tranzila_supplier: response.data.data.schools[0].tranzila_supplier,
            tranzila_password: response.data.data.schools[0].tranzila_password,
            signupNumberOfAllowedPayments: response.data.data.schools[0].signupNumberOfAllowedPayments,
            insideTestNumberOfAllowedPayments: response.data.data.schools[0].insideTestNumberOfAllowedPayments,
            haveInsideTests: response.data.data.schools[0].haveInsideTests,
            haveSignUpFee: response.data.data.schools[0].haveSignUpFee,
            canManagerPayToEmployees: response.data.data.schools[0].canManagerPayToEmployees,

            wavesCommission: response.data.data.schools[0].wavesCommission,
            ptors: response.data.data.schools[0].ptors,
            wavesCommissionSignupTimes: response.data.data.schools[0].wavesCommissionSignupTimes,
            wavesCommissionInsideTestTimes: response.data.data.schools[0].wavesCommissionInsideTestTimes,
            wavesCommissionSignupFirstPayment: response.data.data.schools[0].wavesCommissionSignupFirstPayment,
            wavesCommissionSignupSecondPayment: response.data.data.schools[0].wavesCommissionSignupSecondPayment,
            wavesCommissionInsideTestFirstPayment: response.data.data.schools[0].wavesCommissionInsideTestFirstPayment,
            wavesCommissionInsideTestSecondPayment: response.data.data.schools[0].wavesCommissionInsideTestSecondPayment,
            signupFeeText: response.data.data.schools[0].signupFeeText,
          })
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    });
  }

  updateSchool = () => {
    let payload = {
      name: this.state.schoolName,
      address: this.state.address,
      postalCode: this.state.postalCode,
      schoolEmail: this.state.schoolEmail,
      officialIdNumber: this.state.officialIdNumber,
      mailAddress: this.state.mailAddress,
      CPAEmail: this.state.CPAEmail,

      signupNumberOfAllowedPayments: this.state.signupNumberOfAllowedPayments,
      insideTestNumberOfAllowedPayments: this.state.insideTestNumberOfAllowedPayments,

      invoiceNumber: this.state.invoiceNumber,
      receiptTaxInvoiceNumber: this.state.receiptTaxInvoiceNumber,
      receiptNumber: this.state.receiptNumber,
      isBranch: this.state.isBranch,
      tranzila_supplier: this.state.tranzila_supplier,
      tranzila_password: this.state.tranzila_password,
      parentId: this.state.isBranch ? this.state.parentSchoolId : '',

      frozen: this.state.frozen,

      wavesCommission: this.state.wavesCommission,
      wavesCommissionSignupTimes: this.state.wavesCommissionSignupTimes,
      wavesCommissionInsideTestTimes: this.state.wavesCommissionInsideTestTimes,
      wavesCommissionSignupFirstPayment: this.state.wavesCommissionSignupFirstPayment,
      wavesCommissionSignupSecondPayment: this.state.wavesCommissionSignupSecondPayment,
      wavesCommissionInsideTestFirstPayment: this.state.wavesCommissionInsideTestFirstPayment,
      wavesCommissionInsideTestSecondPayment: this.state.wavesCommissionInsideTestSecondPayment,
      displayPermissionsNumberTesting: this.state.displayPermissionsNumberTesting,
      haveInsideTests: this.state.haveInsideTests,
      haveSignUpFee: this.state.haveSignUpFee,
      canManagerPayToEmployees: this.state.canManagerPayToEmployees,
      signupFeeText: this.state.signupFeeText,
    }

    axios.put(API + 'root/school?id='+this.state.schoolId, payload, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        alert('בית הספר עודכן בהצלחה');
        window.location.href='/';
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  removeSchool = () => {
    if (window.confirm('האם אתה בטוח שברצונך למחוק בית ספר זה')) {
      axios.delete(API + 'root/school?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert('בית הספר נמחק בהצלחה');
          window.location.href = '/';
        } else {
          if (response.data.errorCode === 122) {
            alert(' אין אפשרו למחוק בית ספר מאוכלס, יש למחוק ראשית את כל המשתמשים והתלמידים שלו ' + response.data.message);
          } else {
            alert('שגיאה, אנא נסו שוב');
          }
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    } else {
      alert('המחיקה בוטלה');
    }
  }

  handleChange = () => {
    let isBranch = this.state.isBranch ? false : true;

    this.setState({isBranch: isBranch});
  }

  freeze = () => {
    let frozen = this.state.frozen ? false : true;

    this.setState({frozen: frozen});
  }

  handleChangewavesCommission = () => {
    let wavesCommission = this.state.wavesCommission;

    if (wavesCommission) {
      this.setState({wavesCommission: false});
    } else {
      this.setState({wavesCommission: true});
    }
  }
  handleCanManagerPayToEmployees = () => {
    let canManagerPayToEmployees = this.state.canManagerPayToEmployees;

    if (canManagerPayToEmployees) {
      this.setState({canManagerPayToEmployees: false});
    } else {
      this.setState({canManagerPayToEmployees: true});
    }
  }

  handleDisplayPermissionsNumberTesting = () => {
    let displayPermissionsNumberTesting = this.state.displayPermissionsNumberTesting;

    if (displayPermissionsNumberTesting) {
      this.setState({displayPermissionsNumberTesting: false});
    } else {
      this.setState({displayPermissionsNumberTesting: true});
    }
  }

  handleInvoiceNumber = () => {
    let invoiceNumber = this.state.invoiceNumber;

    if (invoiceNumber) {
      this.setState({invoiceNumber: false});
    } else {
      this.setState({invoiceNumber: true});
    }
  }

  handleRequireInvoiceNumber = () => {
    let requireInvoiceNumber = this.state.requireInvoiceNumber;

    if (requireInvoiceNumber) {
      this.setState({requireInvoiceNumber: false});
    } else {
      this.setState({requireInvoiceNumber: true});
    }
  }

  createPtor = (reason, invoiceNumber, requireInvoiceNumber, teacherReceiveComission, ptorPrecentages) => {
    axios.post(API + 'root/school/ptor', {
      reason: reason,
      invoiceNumber: invoiceNumber,
      requireInvoiceNumber: requireInvoiceNumber,
      schoolId: this.state.school._id,
      teacherReceiveComission: teacherReceiveComission,
      ptorPrecentages: ptorPrecentages
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({reason: '', invoiceNumber: false, requireInvoiceNumber: false, teacherReceiveComission: false})
      }
      this.getSchoolFromQuery();
    })
    .catch(error => {
      // alert('שגיאה, אנא נסו שוב');
    });
  }

  removePtor = (reason) => {
    axios.delete(API + 'root/school/ptor?id='+this.state.school._id+'&reason='+reason, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      this.getSchoolFromQuery();
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  updatePtor = (reason, invoiceNumber, requireInvoiceNumber, teacherReceiveComission, ptorPrecentages = 100) => {
    axios.put(API + 'root/school/ptor', {
      reasonOriginal: this.state.ptorChosen.reason,
      reason: reason,
      invoiceNumber: invoiceNumber,
      requireInvoiceNumber: requireInvoiceNumber,
      schoolId: this.state.school._id,
      teacherReceiveComission: teacherReceiveComission,
      ptorPrecentages: ptorPrecentages
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({reason: '', invoiceNumber: false, requireInvoiceNumber: false, teacherReceiveComission: false})
      }
      this.getSchoolFromQuery();
    })
    .catch(error => {
      // alert('שגיאה, אנא נסו שוב');
    });
  }

  updatePtorActive = (index) => {
    console.log(index);
    this.setState({
      ptorChosen: index,
      ptorActive: true,
      invoiceNumber: index.invoiceNumber,
      requireInvoiceNumber: index.requireInvoiceNumber,
      teacherReceiveComission: index.teacherReceiveComission === undefined ? false : index.teacherReceiveComission,
      reason: index.reason,
      ptorPrecentages: index.ptorPrecentages
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2>עריכת בית הספר</h2>


              {this.state.children === 0 ? (
                <Button onClick={(e) => this.removeSchool()} variant="contained" style={{marginTop: '0%', fontSize: '1em', width: '20%', backgroundColor: 'red', color: 'white', float: 'right'}}>
                  מחיקת בית הספר
                </Button>
              ) : (
                <Button onClick={(e) => alert('אין אפשרות למחוק בית ספר עם סניפים פעילים. אנא מחק את הסניפים ולאחר מכן נסה שוב')} variant="contained" style={{marginTop: '0%', fontSize: '1em', width: '20%', backgroundColor: '#717171', color: 'white', float: 'right'}}>
                  מחיקת בית הספר
                </Button>
              )}

              <br /><br /><br />

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.haveSignUpFee}
                  onChange={(e) => this.handleHaveSignUpFee()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם קיימת אגרת רישום
              </div>
              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.haveInsideTests}
                  onChange={(e) => this.handleHaveInsideTests()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם קיימים מבחנים פנימיים
              </div>
              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.canManagerPayToEmployees}
                  onChange={(e) => this.handleCanManagerPayToEmployees()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם מנהל יכול לרשום תשלום למורה/מנהל מקצועי
              </div>

              <div style={{float: 'right', fontWeight: 'bold'}}>
                <Checkbox
                   checked={this.state.displayPermissionsNumberTesting}
                   onChange={(e) => this.handleDisplayPermissionsNumberTesting()}
                   color={'primary'}
                   inputProps={{ 'aria-label': 'primary checkbox' }}
                /> מספור הרשאות
              </div>
              <br /><br />
              <br /><br />

              <div style={{float: 'right', fontWeight: 'bold'}}>
                <Switch
                   checked={this.state.frozen}
                   onChange={(e) => this.freeze()}
                /> ?הקפאת בית הספר
              </div>

              <br /><br />

              <div style={{maxWidth: '90%'}}>
                <h2>פטורים</h2>

                <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סיבת פטור</p>
                <div style={{float: 'right', fontWeight: 'bold'}}>
                  <Checkbox
                     checked={this.state.invoiceNumber}
                     onChange={(e) => this.handleInvoiceNumber()}
                     color={'primary'}
                     inputProps={{ 'aria-label': 'primary checkbox' }}
                  /> אסמכתא
                </div>
                <div style={{float: 'right', fontWeight: 'bold'}}>
                  <Checkbox
                     checked={this.state.requireInvoiceNumber}
                     onChange={(e) => this.handleRequireInvoiceNumber()}
                     color={'primary'}
                     inputProps={{ 'aria-label': 'primary checkbox' }}
                  /> חובת הקשת מספר אסמכתא
                </div>
                <div style={{float: 'right', fontWeight: 'bold'}}>
                  <Checkbox
                     checked={this.state.teacherReceiveComission}
                     onChange={(e) => this.setState({teacherReceiveComission: this.state.teacherReceiveComission ? false : true})}
                     color={'primary'}
                     inputProps={{ 'aria-label': 'primary checkbox' }}
                  /> האם מורה מקבל עמלה
                </div>

                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.reason}
                  onChange={(e) => this.setState({reason: e.target.value})}
                />

                <Input
                  type='number'
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  value={this.state.ptorPrecentages}
                  onChange={(e) => this.setState({ptorPrecentages: e.target.value})}
                />

                {this.state.ptorActive ? (
                  <>
                  <Button onClick={(e) => this.setState({
                    ptorActive: false,
                    reason: '',
                    teacherReceiveComission: false,
                    requireInvoiceNumber: false,
                    invoiceNumber: false
                  })} variant="contained" color="secondary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                    ביטול
                  </Button>
                  &nbsp;&nbsp;
                  <Button onClick={(e) => this.updatePtor(this.state.reason, this.state.invoiceNumber, this.state.requireInvoiceNumber, this.state.teacherReceiveComission, this.state.ptorPrecentages)} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                    עדכן סיבת פטור
                  </Button>
                  </>
                ) : (
                  <Button onClick={(e) => this.createPtor(this.state.reason, this.state.invoiceNumber, this.state.requireInvoiceNumber, this.state.teacherReceiveComission, this.state.ptorPrecentages)} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                    הוספת סיבת פטור חדשה
                  </Button>
                )}
                <br /><br />
                {this.state.ptors.length > 0 && (
                  <table style={{borderCollapse: 'collapse', width: '100%'}}>
                    <tr style={{border: '1px solid black', padding: 8}}>
                      <th>עדכן</th>
                      <th>מחק</th>
                      <th style={{border: '1px solid black', padding: 8}}>אחוז (אם לא מופיע, 100% הנחה)</th>
                      <th style={{border: '1px solid black', padding: 8}}>האם מורה מקבל עמלה</th>
                      <th style={{border: '1px solid black', padding: 8}}>חובת הקשה</th>
                      <th style={{border: '1px solid black', padding: 8}}>אסתמכא</th>
                      <th style={{border: '1px solid black', padding: 8}}>פטור</th>
                    </tr>
                    {this.state.ptors.reverse().map((index) => {
                      return (
                        <tr style={{border: '1px solid black', padding: 8}}>
                          <th style={{border: '1px solid black', padding: 8}}><Button onClick={(e) => this.updatePtorActive(index)}>עדכן פטור</Button></th>
                          <th style={{border: '1px solid black', padding: 8}}><Button onClick={(e) => this.removePtor(index.reason)}>מחק סיבת פטור</Button></th>
                          <th style={{border: '1px solid black', padding: 8}}>{index.ptorPrecentages}</th>
                          <th style={{border: '1px solid black', padding: 8}}>{index.teacherReceiveComission ? 'כן' : 'לא'}</th>
                          <th style={{border: '1px solid black', padding: 8}}>{index.requireInvoiceNumber ? 'כן' : 'לא'}</th>
                          <th style={{border: '1px solid black', padding: 8}}>{index.invoiceNumber ? 'כן' : 'לא'}</th>
                          <th style={{border: '1px solid black', padding: 8}}>{index.reason}</th>
                        </tr>
                      );
                    })}
                  </table>
                )}

              </div>
              <br /><br />

              <p style={{textAlign: 'right'}}>שם בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.schoolName}
                onChange={(e) => this.setState({schoolName: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>כתובת בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.address}
                onChange={(e) => this.setState({address: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>מיקוד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.postalCode}
                onChange={(e) => this.setState({postalCode: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>אי-מייל בית הספר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.schoolEmail}
                onChange={(e) => this.setState({schoolEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>אי-מייל רואה חשבון</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.CPAEmail}
                onChange={(e) => this.setState({CPAEmail: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>ח.פ/ע.מ</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.officialIdNumber}
                onChange={(e) => this.setState({officialIdNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>כתובת למשלוח דואר</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.mailAddress}
                onChange={(e) => this.setState({mailAddress: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית מס קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.receiptTaxInvoiceNumber}
                onChange={(e) => this.setState({receiptTaxInvoiceNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>חשבונית</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.invoiceNumber}
                onChange={(e) => this.setState({invoiceNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>קבלה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.receiptNumber}
                onChange={(e) => this.setState({receiptNumber: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>מספר דמי רישום לתלמיד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.signupNumberOfAllowedPayments}
                onChange={(e) => this.setState({signupNumberOfAllowedPayments: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>מספר מ.פ לחיוב תלמיד</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                type='number'
                value={this.state.insideTestNumberOfAllowedPayments}
                onChange={(e) => this.setState({insideTestNumberOfAllowedPayments: e.target.value})}
              />

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.wavesCommission}
                  onChange={(e) => this.handleChangewavesCommission()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם מתבצע פיצול אגרות
              </div>

              {this.state.wavesCommission && (
                <React.Fragment>
                <p style={{textAlign: 'right'}}>מספר אגרות רישום ראשוני (עד תשלום מלא)</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionSignupTimes}
                  onChange={(e) => this.setState({wavesCommissionSignupTimes: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>מספר אגרות מבחן פנימי ראשוני (עד תשלום מלא)</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionInsideTestTimes}
                  onChange={(e) => this.setState({wavesCommissionInsideTestTimes: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות רישום ראשוני</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionSignupFirstPayment}
                  onChange={(e) => this.setState({wavesCommissionSignupFirstPayment: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות רישום מלא</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionSignupSecondPayment}
                  onChange={(e) => this.setState({wavesCommissionSignupSecondPayment: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות מ.פ ראשוני</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionInsideTestFirstPayment}
                  onChange={(e) => this.setState({wavesCommissionInsideTestFirstPayment: e.target.value})}
                />

                <p style={{textAlign: 'right'}}>תשלום עמלת אגרות מ.פ מלא</p>
                <TextField
                  style={{
                    direction: 'rtl',
                    width: '100%'
                  }}
                  type='number'
                  value={this.state.wavesCommissionInsideTestSecondPayment}
                  onChange={(e) => this.setState({wavesCommissionInsideTestSecondPayment: e.target.value})}
                />
                </React.Fragment>
              )}

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.isBranch}
                  onChange={(e) => this.handleChange()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ?האם חלק מרשת
              </div>

              {this.state.isBranch && (
                <React.Fragment>
                  <p style={{textAlign: 'right', fontWeight: 'bold'}}>בחר את שם הרשת מתוך הרשימה</p>
                  <Select
                    labelId="demo-simple-select-label"
                    id="demo-simple-select"
                    value={this.state.parentSchoolId}
                    onChange={(e) => this.setState({parentSchoolId: e.target.value})}
                    style={{
                      direction: 'rtl',
                      width: '100%'
                    }}
                  >
                    {this.state.schools.map((school) => {
                      if (!school.isBranch && school._id !== this.state.schoolId) {
                        return (
                          <MenuItem value={school._id} style={{direction: 'rtl', textAlign: 'right'}}>{school.name}</MenuItem>
                        )
                      }
                    })}
                  </Select>
                </React.Fragment>
              )}


              <p style={{textAlign: 'right'}}>שם המסוף</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.tranzila_supplier}
                onChange={(e) => this.setState({tranzila_supplier: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>סיסמת זיכויים / ביטולים של המסוף</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.tranzila_password}
                onChange={(e) => this.setState({tranzila_password: e.target.value})}
              />

              <p style={{textAlign: 'right'}}>שינוי טקסט עמלת דמי רישום</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.signupFeeText}
                onChange={(e) => this.setState({signupFeeText: e.target.value})}
              />


              <Button onClick={(e) => this.updateSchool()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                עריכה
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditSchool;
