import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class AddClerk extends Component {
  componentDidMount() {
  }

  constructor(props) {
   super(props);
   this.state = {
     username: '',
     password: '',
     viewBoard: false,
     deleteBoard: false,
     approveBoard: false,
     updateBoard: false,
     viewCategories: false,
     categoriesColors: false,
     manageGuests: false,
     deleteGuests: false,
     systemMessages: false,
     deleteSystemMessages: false,
   }
  }

  create = async () => {
      let payload = {
        username: this.state.username,
        password: this.state.password,
        viewBoard: this.state.viewBoard,
        deleteBoard: this.state.deleteBoard,
        approveBoard: this.state.approveBoard,
        updateBoard: this.state.updateBoard,
        viewCategories: this.state.viewCategories,
        categoriesColors: this.state.categoriesColors,
        manageGuests: this.state.manageGuests,
        deleteGuests: this.state.deleteGuests,
        systemMessages: this.state.systemMessages,
        deleteSystemMessages: this.state.deleteSystemMessages,
      };

      axios.post(API + 'root/clerk', payload, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        if (response.data.status === "ok") {
          alert('פקיד מערכת נוצר בהצלחה');
          window.location.href='/';
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });

  }


  handleViewBoard = () => {
    let value = this.state.viewBoard;

    this.setState({viewBoard: value ? false : true});
  }

  handleDeleteBoard = () => {
    let value = this.state.deleteBoard;

    this.setState({deleteBoard: value ? false : true});
  }

  handleApproveBoard = () => {
    let value = this.state.approveBoard;

    this.setState({approveBoard: value ? false : true});
  }

  handleUpdateBoard = () => {
    let value = this.state.updateBoard;

    this.setState({updateBoard: value ? false : true});
  }

  handleViewCategories = () => {
    let value = this.state.viewCategories;

    this.setState({viewCategories: value ? false : true});
  }

  handleCategoriesColors = () => {
    let value = this.state.categoriesColors;

    this.setState({categoriesColors: value ? false : true});
  }

  handleManageGuests = () => {
    let value = this.state.manageGuests;

    this.setState({manageGuests: value ? false : true});
  }

  handleDeleteGuests = () => {
    let value = this.state.deleteGuests;

    this.setState({deleteGuests: value ? false : true});
  }

  handleSystemMessages = () => {
    let value = this.state.systemMessages;

    this.setState({systemMessages: value ? false : true});
  }

  handleDeleteMessages = () => {
    let value = this.state.deleteSystemMessages;

    this.setState({deleteSystemMessages: value ? false : true});
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h3 style={{textAlign: 'right'}}>הוספת פקיד מערכת</h3>


              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> שם משתמש באנגלית</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.username}
                onChange={(e) => this.setState({username: e.target.value})}
              />

              <p style={{textAlign: 'right'}}><span style={{color: 'red'}}>*</span> סיסמה</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '100%'
                }}
                value={this.state.password}
                onChange={(e) => this.setState({password: e.target.value})}
              />

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.viewBoard}
                  onChange={(e) => this.handleViewBoard()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> צפייה בלוח מודעות
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.deleteBoard}
                  onChange={(e) => this.handleDeleteBoard()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> מותר למחוק מודעות
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.approveBoard}
                  onChange={(e) => this.handleApproveBoard()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> מותר לאשר מודעות
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.updateBoard}
                  onChange={(e) => this.handleUpdateBoard()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> מותר לעדכן מודעות
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.viewCategories}
                  onChange={(e) => this.handleViewCategories()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> צפייה וניהול קטגוריות
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.categoriesColors}
                  onChange={(e) => this.handleCategoriesColors()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> קטגוריות רק צבעים
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.manageGuests}
                  onChange={(e) => this.handleManageGuests()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> ניהול אורחים
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.deleteGuests}
                  onChange={(e) => this.handleDeleteGuests()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> מותר למחוק אורח
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.systemMessages}
                  onChange={(e) => this.handleSystemMessages()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> הודעות מיצירת קשר
              </div>

              <div style={{textAlign: 'right'}}>
                <Checkbox
                  checked={this.state.deleteSystemMessages}
                  onChange={(e) => this.handleDeleteMessages()}
                  color={'primary'}
                  inputProps={{ 'aria-label': 'primary checkbox' }}
                /> מחיקת הודעות מיצירת קשר
              </div>

              <Button onClick={(e) => this.create()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                להוספה
              </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default AddClerk;
