import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class TeachersSMS extends Component {
  componentDidMount () {
    this.getData();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: false,
     parentSchoolId: 10,
     frozen: false,
     canAddEmployees: false,
     type: '',
     teachers: [],
     output: {},
     schoolById: {},
     schoolChosen: ''
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  getData = () => {
    axios.get(API + 'root/teachers-sms', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          teachers: response.data.data.teachers,
          output: response.data.data.output,
          schoolById: response.data.data.schoolById,
          schoolChosen: Object.keys(response.data.data.schoolById)[0]
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeSMS = (id, type) => {
    axios.put(API + 'root/teachers-sms?id='+id+'&type='+type, {}, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }


  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>הגדרות מסרונים למורים</h2>

              <center>
                <h4>בחר בית ספר</h4>
              </center>
              {Object.keys(this.state.schoolById).map((school) => {
                if (this.state.schoolChosen === school) {
                  return (
                    <Button variant={'contained'} color={'secondary'} style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}}>{this.state.schoolById[school].name}</Button>
                  )
                } else {
                  return (
                    <Button variant={'contained'} color={'primary'} style={{marginRight: '1%', marginLeft: '1%', marginBottom: '1%'}} onClick={(e) => this.setState({schoolChosen: school})}>{this.state.schoolById[school].name}</Button>
                  )
                }
              })}

              <table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון בתשלום דמי מ.פ</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון בתשלום דמי רישום</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון בהרשמת תלמיד</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>מספר טלפון</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>ת.ז</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם המורה</th>
                </tr>
                {
                  this.state.output[this.state.schoolChosen] === undefined ? (
                    <p style={{textAlign: 'center', direction: 'rtl'}}>טוען..</p>
                  ) : (
                    <>
                    {this.state.output[this.state.schoolChosen === '' ? Object.keys(this.state.schoolById)[0] : this.state.schoolChosen].map((index) => {
                      return (
                        <tr>
                          <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changeSMS(index._id, 'insideTestFee')}>{index.smsInsideTestFee ? 'כן' : 'לא'}</Button></td>
                          <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changeSMS(index._id, 'signupFee')}>{index.smsSignupFee ? 'כן' : 'לא'}</Button></td>
                          <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changeSMS(index._id, 'signup')}>{index.smsSignup ? 'כן' : 'לא'}</Button></td>
                          <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.phoneNumber}</td>
                          <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.idNumber}</td>
                          <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.firstName + ' ' + index.lastName}</td>
                        </tr>
                      );
                    })}
                    </>
                  )
                }

              </table>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default TeachersSMS;
