import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class Stores extends Component {
  componentDidMount () {
    this.getStores();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     stores: [],
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  getStores = () => {
    axios.get(API + 'root/stores', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          stores: response.data.data.stores
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }



  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>חנויות</h2>

              <Link to='/store/add' style={{textDecoration: 'none', color: 'white'}}>
                <Button color={'primary'} variant={'contained'}>
                  צור חנות חדשה
                </Button>
              </Link>

              <br /><br />
              <table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>עריכה</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>נוצר בתאריך</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>יש מכירות רגילות</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>יש מכירות רגע אחרון</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לוגו</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>סלוגן</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם המנהל</th>
                </tr>
                {this.state.stores.map((index) => {
                  console.log(index._id);
                  let createdAt = new Date(index.createdAt);

                  createdAt = createdAt.getDate() + '/' + (createdAt.getMonth() + 1) + '/' + createdAt.getFullYear();

                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Link to={'/store/edit?id='+index._id} style={{textDecoration: 'none', color: 'black'}}>ערוך</Link></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{createdAt}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.regular ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.lastMinute ? 'כן' : 'לא'}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><img src={'https://sivan-school.com/images/'+index.logo} style={{width: 50, height: 50}}/></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.slogan}</td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.user.firstName + ' ' + index.user.lastName}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Stores;
