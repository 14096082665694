import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

import imageCompression from 'browser-image-compression';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditStore extends Component {
  componentDidMount () {
    this.getUsers();
    this.validate();
    this.getStore();
  }

  constructor(props) {
   super(props);
   this.state = {
     users: [],
     lastMinute: false,
     regular: false,
     file: null,
     slogan: '',
     userId: '',
     storeId: ''
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }


  getUsers = () => {
    axios.get(API + 'root/users-list', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          users: response.data.data.users,
          userId: response.data.data.users[0]._id
        });
      }
    })
    .catch(error => {
      console.log(error);
    });
  }


  getStore = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({storeId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/store?id='+this.state.storeId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            userId: response.data.data.store.userId,
            slogan: response.data.data.store.slogan,
            lastMinute: response.data.data.store.lastMinute,
            regular: response.data.data.store.regular,
          });
        }
      })
      .catch(error => {
        console.log(error);
      });
    });
  }


  onChange = (e) => {
      this.setState({file:e.target.files[0]}, () => {
        // console.log(this.fileSizeLimit());
        // if (!this.fileSizeLimit()) {
        //   alert('אנא בחרו תמונה מתחת ל2 מגה בייט');
        // }
      });
  }

  onFormSubmit = async (e) => {
    this.setState({loading: true});

    e.preventDefault();
    const formData = new FormData();

    if (this.state.file !== null) {
      const options = {
        maxSizeMB: 1,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }

      const compressedFile = await imageCompression(this.state.file, options);
      const convertedBlobFile = new File([compressedFile], this.state.file.name, { type: this.state.file.type, lastModified: Date.now()})
      console.log(convertedBlobFile);
      formData.append('file', convertedBlobFile);
    }
    formData.append('lastMinute', this.state.lastMinute ? true : this.state.lastMinute);
    formData.append('regular', this.state.regular ? true : this.state.regular);
    formData.append('userId',this.state.userId);
    formData.append('slogan',this.state.slogan);
    formData.append('storeId',this.state.storeId);

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('token__school')}`
        }
    };

    axios.put(API + 'root/store',formData,config)
    .then((response) => {
      window.location.href='/stores';
      this.setState({loading: false});
    }).catch((error) => {
      console.log(error);
      this.setState({loading: false});
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <div style={{maxWidth: '90%', textAlign: 'right', direction: 'rtl'}}>
              <h2 style={{textAlign: 'right'}}>הוספת חנות חדשה</h2>

              <form onSubmit={this.onFormSubmit}>
              <p>מנהל החנות:</p>
              <Select
                value={this.state.userId}
                onChange={(e) => this.setState({userId: e.target.value})}
                style={{
                  width: '50%'
                }}
              >
                {this.state.users.map((index) => {
                  return (
                    <MenuItem value={index._id}>{index.firstName + ' ' + index.lastName}</MenuItem>
                  );
                })}
              </Select>

              <p>סלוגן:</p>
              <TextField
                value={this.state.slogan}
                onChange={(e) => this.setState({slogan: e.target.value})}
                style={{
                  width: '50%'
                }}
              />

              <p>תמונת פרופיל:</p>
              <div style={{textAlign: 'right'}}>
              <br />
              <div class="upload-btn-wrapper">
                <label for="file-upload" class="custom-file-upload">
                    <i class="fa fa-cloud-upload"></i> בחר תמונת לוגו
                </label>
                <input type="file" id="file-upload" name="myImage" onChange={this.onChange} accept="image/*"/>
              </div>


              {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                <React.Fragment>
                {console.log(this.state.file.size)}
                <p style={{color: 'black', fontSize: '1em', direction: 'rtl', marginRight: '5%'}}>קובץ נבחר: {this.state.file.name}</p>
                </React.Fragment>
              )}

              <br />

              <Checkbox
                checked={this.state.lastMinute}
                onChange={(e) => this.setState({lastMinute: this.state.lastMinute ? false : true})}
              /> אפשר מכירת מסוג ברגע האחרון

              <br />

              <Checkbox
                checked={this.state.regular}
                onChange={(e) => this.setState({regular: this.state.regular ? false : true})}
              /> אפשר מכירות רגילות

              <br />

              <Button type="submit" variant={'contained'} color={'primary'}>עדכון חנות</Button>
              </div>
            </form>

            </div>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditStore;
