import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Input from '@material-ui/core/Input';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';

import Switch from '@material-ui/core/Switch';


import axios from 'axios';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditSchoolSmallCash extends Component {
  componentDidMount() {
    this.getSchoolFromQuery();
    this.getSchools();
  }

  constructor(props) {
   super(props);
   this.state = {
     schoolId: '',
     school: {},
     schoolName: '',
     isBranch: false,
     parentSchoolId: '',

     schools: [],
     children: 0,

     address: '',
     postalCode: '',
     schoolEmail: '',
     officialIdNumber: '',
     mailAddress: '',
     CPAEmail: '',
     tranzila_supplier: '',
     tranzila_password: '',

     invoiceNumber: 0,
     receiptTaxInvoiceNumber: 0,
     receiptNumber: 0,
     signupNumberOfAllowedPayments: 0,
     insideTestNumberOfAllowedPayments: 0,

     frozen: false,

     wavesCommission: false,
     wavesCommissionSignupTimes: 0,
     wavesCommissionInsideTestTimes: 0,
     wavesCommissionSignupFirstPayment: 0,
     wavesCommissionSignupSecondPayment: 0,
     wavesCommissionInsideTestFirstPayment: 0,
     wavesCommissionInsideTestSecondPayment: 0,
     displayPermissionsNumberTesting: false,
     canManagerPayToEmployees: false,
     haveInsideTests: false,
     haveSignUpFee: false,
     reason: '',
     ptors: [],
     requireInvoiceNumber: false,
     invoiceNumber: false,

     ptorActive: false,
     ptorChosen: {},
     teacherReceiveComission: false,
     signupFeeText: 'דמי רישום',
     smallcashregisterAmount: '0',
     schoolId: '',
   }
  }


    handleHaveInsideTests = () => {
      let haveInsideTests = this.state.haveInsideTests ? false : true;

      this.setState({haveInsideTests: haveInsideTests});
    }

    handleHaveSignUpFee = () => {
      let haveSignUpFee = this.state.haveSignUpFee ? false : true;

      this.setState({haveSignUpFee: haveSignUpFee});
    }

  getSchools = async () => {
    axios.get(API + 'root/schools', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.setState({
          schools: response.data.data.schools,
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }


  editCashRegister = async () => {
    axios.put(API + 'root/school-cashregister?id='+this.state.schoolId, {
        amount: this.state.smallcashregisterAmount
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        alert('הסכום עודכן בהצלחה')
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getSchoolFromQuery = () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({schoolId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/school?id='+this.state.schoolId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
            try {
                this.setState({smallcashregisterAmount: response.data.data.cashregister.amount.toString()})
            } catch (err) {

            }
            try {
                this.setState({schoolId: response.data.data.schoolId})
            } catch (err) {

            }
          this.setState({
            school: response.data.data.schools[0],
            schoolName: response.data.data.schools[0].name,
            isBranch: response.data.data.schools[0].isBranch,
            parentSchoolId: response.data.data.schools[0].parentId,
            address: response.data.data.schools[0].address,
            postalCode: response.data.data.schools[0].postalCode,
            schoolEmail: response.data.data.schools[0].schoolEmail,
            officialIdNumber: response.data.data.schools[0].officialIdNumber,
            displayPermissionsNumberTesting: response.data.data.schools[0].displayPermissionsNumberTesting,
            mailAddress: response.data.data.schools[0].mailAddress,
            invoiceNumber: response.data.data.schools[0].invoiceNumber,
            receiptTaxInvoiceNumber: response.data.data.schools[0].receiptTaxInvoiceNumber,
            receiptNumber: response.data.data.schools[0].receiptNumber,
            CPAEmail: response.data.data.schools[0].CPAEmail,
            isBranch: response.data.data.schools[0].isBranch,
            children: response.data.data.children,
            frozen: response.data.data.schools[0].frozen,
            tranzila_supplier: response.data.data.schools[0].tranzila_supplier,
            tranzila_password: response.data.data.schools[0].tranzila_password,
            signupNumberOfAllowedPayments: response.data.data.schools[0].signupNumberOfAllowedPayments,
            insideTestNumberOfAllowedPayments: response.data.data.schools[0].insideTestNumberOfAllowedPayments,
            haveInsideTests: response.data.data.schools[0].haveInsideTests,
            haveSignUpFee: response.data.data.schools[0].haveSignUpFee,
            canManagerPayToEmployees: response.data.data.schools[0].canManagerPayToEmployees,

            wavesCommission: response.data.data.schools[0].wavesCommission,
            ptors: response.data.data.schools[0].ptors,
            wavesCommissionSignupTimes: response.data.data.schools[0].wavesCommissionSignupTimes,
            wavesCommissionInsideTestTimes: response.data.data.schools[0].wavesCommissionInsideTestTimes,
            wavesCommissionSignupFirstPayment: response.data.data.schools[0].wavesCommissionSignupFirstPayment,
            wavesCommissionSignupSecondPayment: response.data.data.schools[0].wavesCommissionSignupSecondPayment,
            wavesCommissionInsideTestFirstPayment: response.data.data.schools[0].wavesCommissionInsideTestFirstPayment,
            wavesCommissionInsideTestSecondPayment: response.data.data.schools[0].wavesCommissionInsideTestSecondPayment,
            signupFeeText: response.data.data.schools[0].signupFeeText,
          })
        } else {
          alert('שגיאה, אנא נסו שוב');
        }
      })
      .catch(error => {
        alert('שגיאה, אנא נסו שוב');
      });
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
            <h2>קופה קטנה - {this.state.schoolName}</h2>


                <br /><br />

                <Input
                    type='number'
                    style={{
                    direction: 'rtl',
                    width: '100%'
                    }}
                    value={this.state.smallcashregisterAmount}
                    onChange={(e) => this.setState({smallcashregisterAmount: e.target.value})}
                />


                <Button onClick={(e) => this.editCashRegister()} variant="contained" color="primary" style={{marginTop: '2%', fontSize: '1.2em', width: '30%'}}>
                עריכה
                </Button>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditSchoolSmallCash;
