import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import { ChromePicker } from 'react-color';

import Switch from '@material-ui/core/Switch';

import axios from 'axios';

import { Link } from 'react-router-dom';

const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class SchoolsSMS extends Component {
  componentDidMount () {
    this.getData();
    this.validate();
  }

  constructor(props) {
   super(props);
   this.state = {
     isBranch: false,
     parentSchoolId: 10,
     frozen: false,
     canAddEmployees: false,
     type: '',
     schools: [],
     insideTestTexts: {},
     signupTexts: {},
   }
  }

  validate = () => {
    axios.get(API + 'root/validate', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data);
        this.setState({
          isClerk: response.data.data.isClerk,
          viewBoard: response.data.data.viewBoard,
          deleteBoard: response.data.data.deleteBoard,
          approveBoard: response.data.data.approveBoard,
          updateBoard: response.data.data.updateBoard,
          viewCategories: response.data.data.viewCategories,
          categoriesColors: response.data.data.categoriesColors,
          manageGuests: response.data.data.manageGuests,
          deleteGuests: response.data.data.deleteGuests,
          systemMessages: response.data.data.systemMessages,
          deleteSystemMessages: response.data.data.deleteSystemMessages,
        }, () => {
          console.log(this.state);
        });
      }
    });
  }

  editTextInsideTest = (id, e) => {
    const value = e.target.value;
    let insideTestTexts = this.state.insideTestTexts;
    insideTestTexts[id] = value;

    this.setState({insideTestTexts: insideTestTexts});
  }

  editTextSignup = (id, e) => {
    const value = e.target.value;
    let signupTexts = this.state.signupTexts;
    signupTexts[id] = value;

    this.setState({signupTexts: signupTexts});
  }

  getData = () => {
    axios.get(API + 'root/school-sms-students', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          schools: response.data.schools
        }, () => {
          let insideTestTexts = {};
          let signupTexts = {};
          response.data.schools.map((index) => {
            insideTestTexts[index._id] = index.sendSMSInsideTestFeeStudentsText;
            signupTexts[index._id] = index.sendSMSSignupFeeStudentsText;
          });

          this.setState({insideTestTexts: insideTestTexts, signupTexts: signupTexts})
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  changeSMS = (id, type) => {
    if (type === "signup") {
      axios.put(API + 'root/school-sms-signupfee-students?id='+id, {}, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        } else {
          console.log('else');
        }
      })
      .catch(error => {
        console.log(error);
      });
    } else { // inside test
      axios.put(API + 'root/school-sms-insidetestfee-students?id='+id, {}, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.getData();
        } else {
          console.log('else');
        }
      })
      .catch(error => {
        console.log(error);
      });
    }
  }

  updateTextInsideTest = (id) => {
    const value = this.state.insideTestTexts[id]
    axios.put(API + 'root/school-sms-insidetestfee-students-text?id='+id, {
      text: value
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  updateTextSignup = (id) => {
    const value = this.state.signupTexts[id]
    axios.put(API + 'root/school-sms-signupfee-students-text?id='+id, {
      text: value
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.getData();
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }



  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12}>
            <center>
            <div style={{maxWidth: '90%'}}>
              <h2 style={{textAlign: 'right'}}>הגדרות מסרונים למורים</h2>

              <table>
                <tr>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון אוטומטי לתשלום דמי מ.פ - טקסט</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון אוטומטי לתשלום דמי רישום - טקסט</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון אוטומטי לתשלום דמי מ.פ</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>לשלוח מסרון אוטומטי לתשלום דמי רישום</th>
                  <th style={{border: '1px solid black', textAlign: 'center', padding: 8}}>שם הבית ספר</th>
                </tr>
                {this.state.schools.map((index) => {
                  return (
                    <tr>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><input type="text" value={this.state.insideTestTexts[index._id]} onChange={(e) => this.editTextInsideTest(index._id, e)} style={{marginBottom: '1%'}}/> <br /> <Button onClick={(e) => this.updateTextInsideTest(index._id)} color="primary" variant="contained" >עדכון</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><input type="text" value={this.state.signupTexts[index._id]} onChange={(e) => this.editTextSignup(index._id, e)} style={{marginBottom: '1%'}}/> <br /> <Button onClick={(e) => this.updateTextSignup(index._id)} color="primary" variant="contained" >עדכון</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changeSMS(index._id, 'insidetest')}>{index.sendSMSInsideTestFeeStudents ? 'כן' : 'לא'}</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}><Button onClick={(e) => this.changeSMS(index._id, 'signup')}>{index.sendSMSSignupFeeStudents ? 'כן' : 'לא'}</Button></td>
                      <td style={{border: '1px solid black', textAlign: 'center', padding: 8}}>{index.name}</td>
                    </tr>
                  );
                })}
              </table>
            </div>
            </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default SchoolsSMS;
