import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';
import Checkbox from '@material-ui/core/Checkbox';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Cookies from 'universal-cookie';
import GetAppIcon from '@material-ui/icons/GetApp';
import WhatsAppIcon from '@material-ui/icons/WhatsApp';
import axios from 'axios';

import DateFnsUtils from '@date-io/date-fns';
import {
  MuiPickersUtilsProvider,
  KeyboardTimePicker,
  KeyboardDatePicker,
} from '@material-ui/pickers';


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

class EditMessage extends Component {
  componentDidMount () {
    this.getMessage();
    this.getCategories();
  }

  constructor(props) {
   super(props);
   this.state = {
     messages: [],
     lastSeen: '',
     language: 'he',

     isLastMinute: false,
     lastMinuteMaxDate: new Date(Date.now()),
     price: 0,
     priceWanted: 0,
     phoneNumber: '',
     freetext: '',
     file: null,
     messageId: '',
     message: {},
     categories: [],
     category: '',
     childCategory: ''
   }
  }


  onChange = (e) => {
      this.setState({file:e.target.files[0]}, () => {
        console.log(this.state.file);
      });
  }
  getCategories = () => {
    axios.get(API + 'root/sivanboardcategories', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      console.log(response);
      if (response.data.status === "ok") {
        this.setState({
          categories: response.data.data.categories
        });
      } else {
        console.log('else');
      }
    })
    .catch(error => {
      console.log(error);
    });
  }

  getMessage = async () => {
    let name = 'id';
    let url = window.location.href;
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    this.setState({messageId: decodeURIComponent(results[2].replace(/\+/g, ' '))}, () => {
      axios.get(API + 'root/sivanboardmessage?id='+this.state.messageId, {
        headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
      })
      .then(response => {
        console.log(response);
        if (response.data.status === "ok") {
          this.setState({
            isLastMinute: response.data.data.sivanboardmessage.isLastMinute,
            lastMinuteMaxDate: response.data.data.sivanboardmessage.lastMinuteMaxDate,
            freetext: response.data.data.sivanboardmessage.freetext,
            category: response.data.data.sivanboardmessage.category,
            childCategory: response.data.data.sivanboardmessage.childCategory,
            price: response.data.data.sivanboardmessage.price,
            priceWanted: response.data.data.sivanboardmessage.priceWanted,
            phoneNumber: response.data.data.sivanboardmessage.phoneNumber,
          });
        } else {
          // alert(strings.errorPleaseTryAgain);
        }
      })
      .catch(error => {
        console.log(error);
      });
    })
  }

  handleIsLastMinute = () => {
    let value = this.state.isLastMinute;
    if (value) {
      this.setState({isLastMinute: false});
    } else {
      this.setState({isLastMinute: true});
    }
  }

  onFormSubmit = (e) => {
    e.preventDefault();
    const formData = new FormData();
    formData.append('file',this.state.file);
    formData.append('isLastMinute',this.state.isLastMinute);
    formData.append('lastMinuteMaxDate',this.state.lastMinuteMaxDate);
    formData.append('price',this.state.price);
    formData.append('priceWanted',this.state.priceWanted);
    formData.append('category',this.state.category);
    formData.append('childCategory',this.state.childCategory);
    formData.append('phoneNumber',this.state.phoneNumber);
    formData.append('freetext',this.state.freetext);

    const config = {
        headers: {
            'content-type': 'multipart/form-data',
            Authorization: `Bearer ${cookies.get('token__school')}`
        }
    };
    axios.put(API + 'root/sivanboardmessage?id='+this.state.messageId,formData,config)
    .then((response) => {
      window.location.href='/board';
    }).catch((error) => {
      console.log(error);
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'} lastSeen={this.state.lastSeen}/>
          </Grid>
          <Grid item xs={12}>
            <center>
              <div style={{maxWidth: '90%'}}>
                <h2 style={{textAlign: 'right'}}>עריכת מודעה</h2>
              </div>
            </center>
          </Grid>

          <Grid item xs={12}>
              <form onSubmit={this.onFormSubmit}>
                <br />
                <div style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>
                  <Checkbox
                    checked={this.state.isLastMinute}
                    onChange={(e) => this.handleIsLastMinute()}
                  /> האם מוצר בעל תוקף?
                </div>

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>קטגוריה:</p>
                <Select
                  value={this.state.category}
                  onChange={(e) => this.setState({category: e.target.value})}
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: '90%'
                  }}
                >
                  {this.state.categories.map((category) => {
                    if (category.parentCategory === "" || category.parentCategory === undefined || category.parentCategory === null) {
                      return (
                        <MenuItem   style={{
                            textAlign: 'right',
                            direction: 'rtl',
                          }} value={category.name}>{category.name}</MenuItem>
                      );
                    }
                  })}
                </Select>

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>תת קטגוריה:</p>
                <Select
                  value={this.state.childCategory}
                  onChange={(e) => this.setState({childCategory: e.target.value})}
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: '90%'
                  }}
                >
                  {this.state.categories.map((category) => {
                    if (category.parentCategory !== "" && category.parentCategory !== undefined && category.parentCategory !== null) {
                      if (category.parentCategory === this.state.category) {
                        return (
                          <MenuItem   style={{
                              textAlign: 'right',
                              direction: 'rtl',
                            }} value={category.name}>{category.name}</MenuItem>
                        );
                      }
                    }
                  })}
                </Select>

                {this.state.isLastMinute && (
                  <React.Fragment>
                    <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>תפוגת המוצר:</p>
                    <TextField
                      value={this.state.lastMinuteMaxDate}
                      onChange={(e) => this.setState({lastMinuteMaxDate: e.target.value})}
                      type='date'
                      style={{
                        textAlign: 'right',
                        direction: 'rtl',
                        width: '90%'
                      }}
                    />
                  </React.Fragment>
                )}

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>מחיר:</p>
                <TextField
                  value={this.state.priceWanted}
                  onChange={(e) => this.setState({priceWanted: e.target.value})}
                  type='number'
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: '90%'
                  }}
                />

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>מחיר:</p>
                <TextField
                  value={this.state.price}
                  onChange={(e) => this.setState({price: e.target.value})}
                  type='number'
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: '90%'
                  }}
                />

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>מספר טלפון:</p>
                <TextField
                  value={this.state.phoneNumber}
                  onChange={(e) => this.setState({phoneNumber: e.target.value})}
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: '90%'
                  }}
                />

                <p style={{textAlign: 'right', direction: 'rtl', marginRight: '5%'}}>טקסט חופשי:</p>
                <textarea
                  value={this.state.freetext}
                  onChange={(e) => this.setState({freetext: e.target.value})}
                  style={{
                    textAlign: 'right',
                    direction: 'rtl',
                    width: '90%'
                  }}
                  rows={5}
                ></textarea>
                <br />
                <div style={{textAlign: 'right'}}>
                <br />
                <div class="upload-btn-wrapper" style={{marginRight: '5%'}}>
                  <label for="file-upload" class="custom-file-upload">
                      <i class="fa fa-cloud-upload"></i> לחצו לבחירת תמונת המוצר
                  </label>
                  <input type="file" id="file-upload" name="myImage" onChange={this.onChange} accept="image/*"/>
                </div>

                {(this.state.file !== undefined && this.state.file !== null && this.state.file !== '') && (
                  <React.Fragment>
                  <p style={{color: 'black', fontSize: '1em', direction: 'rtl', marginRight: '5%'}}>הקובץ שנבחר: {this.state.file.name}</p>
                  </React.Fragment>
                )}

                <center><Button type="submit" variant={'contained'} color={'primary'}>להעלאת המודעה לחצו כאן</Button></center>
                </div>
              </form>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default EditMessage;
