import React, { Component } from 'react';
import '../App.css';

import Grid from '@material-ui/core/Grid';

import Header from '../components/Header';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { IconButton } from '@material-ui/core';

import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import EditIcon from '@material-ui/icons/Edit';
import FaceIcon from '@material-ui/icons/Face';
import VisibilityIcon from '@material-ui/icons/Visibility';

import { makeStyles } from '@material-ui/core/styles';
import Cookies from 'universal-cookie';

import axios from 'axios';

import {
  Link
} from "react-router-dom";


const API = 'https://api.sivan-school.com/v1/';
const cookies = new Cookies();

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

class Vat extends Component {
  componentDidMount() {
    this.getData();
    this.getVat();
  }

  constructor(props) {
   super(props);
   this.state = {
     schools: [],
     vats: [],
     vat: '',
     fromDate: new Date(Date.now())
   }
  }

  getData = async () => {
    axios.get(API + 'root/schools-data', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(0);
        this.setState({
          schools: response.data.data.output
        }, () => {
          console.log(this.state.schools);
        })
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  getVat = async () => {
    axios.get(API + 'root/vat', {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        console.log(response.data.data.vats);
        this.setState({
          vats: response.data.data.vats
        });
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  addVat = async () => {
    axios.post(API + 'root/vat', {
      vat: this.state.vat,
      fromDate: this.state.fromDate
    }, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getVat();
        this.setState({vat: '', fromDate: new Date(Date.now())});
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  deleteVat = async (id) => {
    axios.delete(API + 'root/vat?id='+id, {
      headers: { Authorization: `Bearer ${cookies.get('token__school')}` }
    })
    .then(response => {
      if (response.data.status === "ok") {
        this.getVat();
        this.setState({vat: '', fromDate: new Date(Date.now())});
      } else {
        alert('שגיאה, אנא נסו שוב');
      }
    })
    .catch(error => {
      alert('שגיאה, אנא נסו שוב');
    });
  }

  render() {
    return (
      <div className="App">
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Header type={'logged'}/>
          </Grid>
          <Grid item xs={12} style={{marginRight: '5%'}}>
            <h4 style={{textAlign: 'right'}}>הוספת מע"מ חדש</h4>

            <div style={{float: 'right'}}>
              <p style={{textAlign: 'right', fontSize: '1em'}}>מע"מ (%)</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '90%',
                  textAlign: 'right',
                }}
                type={'number'}
                value={this.state.vat}
                onChange={(e) => this.setState({vat: e.target.value})}
              />

              <p style={{textAlign: 'right', fontSize: '1em'}}>מתאריך</p>
              <TextField
                style={{
                  direction: 'rtl',
                  width: '90%',
                  textAlign: 'right',
                }}
                type={'date'}
                value={this.state.fromDate}
                onChange={(e) => this.setState({fromDate: e.target.value})}
              />

              <br /><br />
              <Button onClick={(e) => this.addVat()} variant="contained" color="primary" style={{float: 'right'}}>
                הוסף
              </Button>
            </div>
          </Grid>
          <Grid item xs={12}>
            <center>
              <TableContainer>
               <Table aria-label="simple table" style={{maxWidth: '90%'}} component={Paper}>
                 <TableHead>
                   <TableRow>
                     <TableCell align="right">מחיקה</TableCell>
                     <TableCell align="right">מתאריך</TableCell>
                     <TableCell align="right">מע"מ</TableCell>
                   </TableRow>
                 </TableHead>
                 <TableBody>
                   {this.state.vats.map((row, i) => (
                     <TableRow key={i}>
                       <TableCell align="right"><Button onClick={(e) => this.deleteVat(row._id)}>למחיקה</Button></TableCell>
                       <TableCell align="right">{row.fromDate}</TableCell>
                       <TableCell align="right">{row.vat}</TableCell>
                     </TableRow>
                   ))}
                 </TableBody>
               </Table>
             </TableContainer>
           </center>
          </Grid>
        </Grid>
      </div>
    )
  }
}

export default Vat;
